import React from 'react';

const EpidemicReportTableMD5 = ({ data, selectedDisplay }) => {
    // Parse the data to get the unique years and organize them
    console.log(data)
    let isWeek = false;
    let isMonth = false;
    const getTimePeriod = (detail) => {
        if ('week' in detail) {
            isWeek = true;
            const paddedWeek = String(detail.week).padStart(2, '0');
            return `${detail.year}_${paddedWeek}`; // Formats as "year_week"
        } else if ('month' in detail) {
            isMonth = true;
            const paddedMonth = detail.month;
            return `${detail.year}_${paddedMonth}`; // Formats as "year_month"
        } else {
            return `${detail.year}`;
        }
    };

    // Create a list of unique time periods
    const uniqueTimePeriods = Array.from(
        new Set(data.flatMap(report => report.details.map(getTimePeriod)))
    );

    // Prepare headers based on time periods
    const headers = uniqueTimePeriods.map(timePeriod => ({
        timePeriod,
        subHeaders: [
            'สถานะการแจ้งเตือนโรคระบาด', 
            'จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี', 
            'ยืนยันผู้ป่วย',
            'เสียชีวิต'
        ]
    }));


    function setColorClass(colormd5) {
        switch(colormd5) {
            case 1: return 'lightgrey';
            case 2: return 'green';
            case 3: return 'yellow';
            case 4: return 'orange';
            case 5: return 'red';
            default: return ''; // no additional class
        }
    }

    function setColorClassInfected(color20, color100, selectedDisplay) {
        if(selectedDisplay == "1") {
            switch(color20) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }

        if(selectedDisplay == "2") {
            switch(color100) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }
        
        return '';
    }

    const renderSubHeaders = (subHeader, timePeriod) => {
        if (selectedDisplay === "3" || subHeader !== 'จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี') {
            return <th data-column={subHeader} key={`${timePeriod}-${subHeader}`}>{subHeader}</th>;
        }
        return null;
    };

    const renderDataCells = (detail, key) => {
        if (selectedDisplay === "3") {
            return <td className={setColorClass(detail.colormd5)}>{detail[key] || 0}</td>;
        }
        return null;
    };

    const renderTotalMedianCell = (report) => {
        if (selectedDisplay === "3") {
            return <td className={setColorClass(Math.max(...report.details.map(d => d.colormd5 || 0)))}>
                       {report.totalMedian || 0}
                   </td>;
        }
        return null;
    };

    return (
        <table id="epidemicReportTable">
            <thead>
                <tr>
                    <th>โรค</th>
                    {headers.map(header => (
                        <th key={header.timePeriod} colSpan={header.subHeaders.reduce((total, sh) => {
                            return total + ((sh.subHeaders ? sh.subHeaders.length : 1) * (selectedDisplay === "3" || sh !== 'จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี' ? 1 : 0));
                        }, 0)}>
                            {header.timePeriod}
                        </th>
                    ))}
                    <th colSpan={selectedDisplay === "3" ? "4" : "3"}>รวม</th>
                </tr>
                <tr>
                    <th></th>
                    {headers.flatMap(header =>
                        header.subHeaders.map(subHeader => (
                            typeof subHeader === 'object' ?
                            subHeader.subHeaders.map(sh => renderSubHeaders(sh, header.timePeriod)) :
                            renderSubHeaders(subHeader, header.timePeriod)
                        ))
                    )}
                    {selectedDisplay === "3" && <th>จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี</th>}
                    <th id='infected_header'>ยืนยันผู้ป่วย</th>
                    <th id='deaths_header'>เสียชีวิต</th>
                </tr>
            </thead>
            <tbody>
                {data.map(report => (
                    <tr key={report.epidem_report_id}>
                        <td>{report.epidem_report_name_th}</td>
                        {uniqueTimePeriods.map(timePeriod => {
                            const [year, monthOrWeek] = timePeriod.split('_');
                            const detail = report.details.find(d => {
                                const yearMatches = d.year === parseInt(year, 10);
                                const monthOrWeekMatches = isMonth ? d.month === monthOrWeek : 
                                                                    isWeek ? d.week === parseInt(monthOrWeek, 10) : true;
                                return yearMatches && monthOrWeekMatches;
                            }) || {};
                            return (
                                <React.Fragment key={`${report.epidem_report_id}-${timePeriod}`}>
                                    <td>{detail.flag > 0 ? '!'.repeat(detail.flag) : '-'}</td>
                                    {renderDataCells(detail, 'median')}
                                    <td className={setColorClassInfected(detail.color20, detail.color100, selectedDisplay)}>{detail.infected || 0}</td>
                                    <td>{detail.death || 0}</td>
                                </React.Fragment>
                            );
                        })}
                        {renderTotalMedianCell(report)}
                        <td className={setColorClassInfected(Math.max(...report.details.map(d => d.color20 || 0)), Math.max(...report.details.map(d => d.color100 || 0)), selectedDisplay)}>
                            {report.totalInfected}
                        </td>
                        <td>{report.totalDeath}</td>
                    </tr>
                ))}
            </tbody>

        </table>
    );
};

export default EpidemicReportTableMD5;
