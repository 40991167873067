import React, { useState } from 'react';

const EpidemicReportTableMD5Group = ({ data, getGroupDetail, selectedDisplay }) => {
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [groupDetails, setGroupDetails] = useState({});

    const toggleRowExpansion = async (id) => {
        if (expandedRows.has(id)) {
            setExpandedRows(prev => new Set([...prev].filter(eid => eid !== id)));
        } else {
            setExpandedRows(prev => new Set(prev.add(id)));
            if (!groupDetails[id]) {
                const details = await getGroupDetail(id);
                setGroupDetails(prev => ({ ...prev, [id]: details }));
            }
        }
    };

    const isRowExpanded = (id) => expandedRows.has(id);

    // Function to create a unique identifier for each time period
    const getTimePeriod = (detail) => {
        if ('week' in detail) {
            const paddedWeek = String(detail.week).padStart(2, '0');
        return `${detail.year}_${paddedWeek}`;
        } else if ('month' in detail) {
            return `${detail.year}_${detail.month}`;
        } else {
            return detail.year.toString();
        }
    };

    // Parse the data to get unique time periods
    const uniqueTimePeriods = Array.from(
        new Set(data.flatMap(report => report.details.map(getTimePeriod)))
    );

    const headers = uniqueTimePeriods.map(timePeriod => ({
        timePeriod,
        subHeaders: [
            'สถานะการแจ้งเตือนโรคระบาด',
            ...(selectedDisplay === "3" ? ['จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี'] : []),
            {
                header: 'จำนวนผู้ป่วย',
                subHeaders: ['ผู้ป่วย', 'เสียชีวิต', 'ไม่ทราบ']
            }
        ]
    }));


    const setColorClass = (colormd5) => {
        switch(colormd5) {
            case 1: return 'lightgrey';
            case 2: return 'green';
            case 3: return 'yellow';
            case 4: return 'orange';
            case 5: return 'red';
            default: return '';
        }
    };

    function setColorClassInfected(color20, color100, selectedDisplay) {
        if(selectedDisplay == "1") {
            switch(color20) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }

        if(selectedDisplay == "2") {
            switch(color100) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }
        
        return '';
    }

    const renderDataCells = (detail, key) => {
        console.log(detail)
        if (key === 'infected' && detail != undefined) {
            return <td className={setColorClassInfected(detail.color20, detail.color100, selectedDisplay)}>{detail[key] || 0}</td>;
        }
        else if (selectedDisplay === "3" && detail != undefined && key === 'median') {
            return <td className={setColorClass(detail.colormd5)}>{detail[key] || 0}</td>;
        }
        else if ((key === 'death' || key === 'unknown')  && detail != undefined) {
            return <td>{detail[key] || 0}</td>;
        }
        else if (selectedDisplay !== "3") {
            return <td>0</td>;
        }
        
    };

    return (
        <table id="epidemicReportTable">
            <thead>
                <tr>
                    <th>โรค</th>
                    {headers.map(header => (
                        <th key={header.timePeriod} colSpan={header.subHeaders.reduce((total, sh) => (
                            total + (sh.subHeaders ? sh.subHeaders.length : 1)
                        ), 0)}>
                            {header.timePeriod}
                        </th>
                    ))}
                </tr>
                <tr>
                    <th></th>
                    {headers.flatMap(header =>
                        header.subHeaders.map(subHeader => (
                            typeof subHeader === 'object'
                                ? subHeader.subHeaders.map(sh => <th key={`${header.timePeriod}-${subHeader.header}-${sh}`}>{sh}</th>)
                                : <th key={`${header.timePeriod}-${subHeader}`}>{subHeader}</th>
                        ))
                    )}
                </tr>
            </thead>
            <tbody>
                {data.map(report => (
                    <React.Fragment key={report.epidem_group_id}>
                        <tr>
                            <td>
                                <button onClick={() => toggleRowExpansion(report.epidem_group_id)}>
                                    {isRowExpanded(report.epidem_group_id) ? '-' : '+'}
                                </button>
                                {report.epidem_group_name_th}
                            </td>
                            {uniqueTimePeriods.map(timePeriod => {
                                const detail = report.details.find(d => getTimePeriod(d) === timePeriod) || {};
                                return (
                                    <React.Fragment key={`${report.epidem_group_id}-${timePeriod}`}>
                                        <td>{detail.flag > 0 ? '!'.repeat(detail.flag) : '-'}</td>
                                        {selectedDisplay === "3" && <td className={setColorClass(detail.colormd5)}>{detail.median || 0}</td>}
                                        {renderDataCells(detail, 'infected')}
                                        {renderDataCells(detail, 'death')}
                                        <td>{detail.unknown || 0}</td>
                                    </React.Fragment>
                                );
                            })}
                        </tr>
                        {isRowExpanded(report.epidem_group_id) && groupDetails[report.epidem_group_id] && 
                            groupDetails[report.epidem_group_id].data.data.map(detail => (
                                <tr key={detail.epidem_report_id}>
                                    <td>  - {detail.epidem_report_name_th}</td>
                                    {uniqueTimePeriods.map(timePeriod => {
                                        const detailx = detail.details.find(d => getTimePeriod(d) === timePeriod) || {};
                                        return (
                                            <React.Fragment key={`${detail.epidem_report_id}-${timePeriod}`}>
                                                <td>{detailx.flag > 0 ? '!'.repeat(detailx.flag) : '-'}</td>
                                                {selectedDisplay === "3" && <td className={setColorClass(detailx.colormd5)}>{detailx.median || 0}</td>}
                                                {renderDataCells(detailx, 'infected')}
                                                {renderDataCells(detailx, 'death')}
                                                <td>{detailx.unknown || 0}</td>
                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            ))
                        }
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );

};

export default EpidemicReportTableMD5Group;
