import React from 'react';

const EpidemicReportTableMD5Gender = ({ data, selectedDisplay }) => {
    const hasMonthData = data.some(report => report.details.some(detail => 'month' in detail));
    const hasWeekData = data.some(report => report.details.some(detail => 'week' in detail));

    // Process and group data by year, month/week, and gender
    const processAndGroupData = (data) => {
        const timePeriods = new Set();
        const genders = new Set();

        data.forEach(report => {
            report.details.forEach(detail => {
                let timePeriod = detail.year.toString();
                if ('month' in detail) {
                    timePeriod = `${detail.year}_${detail.month}`;
                } else if ('week' in detail) {
                    timePeriod = `${detail.year}_${detail.week}`;
                }
                timePeriods.add(timePeriod);

                if (detail.gender !== undefined) {
                    genders.add(detail.gender === 1 ? 'Male' : 'Female');
                }
            });
        });

        return { timePeriods: Array.from(timePeriods), genders: Array.from(genders) };
    };

    const { timePeriods, genders } = processAndGroupData(data);

    function setColorClass(colormd5) {
        switch(colormd5) {
            case 1: return 'lightgrey';
            case 2: return 'green';
            case 3: return 'yellow';
            case 4: return 'orange';
            case 5: return 'red';
            default: return '';
        }
    }

    function setColorClassInfected(color20, color100, selectedDisplay) {
        if(selectedDisplay == "1") {
            switch(color20) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }

        if(selectedDisplay == "2") {
            switch(color100) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }
        
        return '';
    }

    const renderSubHeaders = (subHeader, timePeriod) => {
        if (selectedDisplay === "3" || subHeader !== 'จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี') {
            return <th key={`${timePeriod}-${subHeader}`}>{subHeader}</th>;
        }
        return null;
    };

    const renderDataCells = (detail, key) => {
        console.log(detail)
        if (key === 'infected' && detail != undefined) {
            return <td className={setColorClassInfected(detail.color20, detail.color100, selectedDisplay)}>{detail[key] || 0}</td>;
        }
        else if (selectedDisplay === "3" && detail != undefined && key === 'median') {
            return <td className={setColorClass(detail.colormd5)}>{detail[key] || 0}</td>;
        }
        else if ((key === 'death' || key === 'unknown')  && detail != undefined) {
            return <td>{detail[key] || 0}</td>;
        }
        else if (selectedDisplay !== "3") {
            return <td>0</td>;
        }
        
    };

    const renderTotalMedianCell = (report) => {
        if (selectedDisplay === "3") {
            return <td className={setColorClass(Math.max(...report.details.map(d => d.colormd5 || 0)))}>
                       {report.totalMedian || 0}
                   </td>;
        }
        return null;
    };

    const calculateTotalColSpan = () => {
        // Count the number of sub-headers per time period and gender
        let subHeaderCount = 0;
        timePeriods.forEach(() => {
            genders.forEach(() => {
                // Add 4 for each gender (flag, median, infected, death)
                // If selectedDisplay is not "3", don't count the median column
                subHeaderCount += (selectedDisplay === "3" ? 4 : 3);
            });
        });
        return subHeaderCount;
    };

    return (
        <table id="epidemicReportTable">
             <thead>
                <tr>
                    <th>โรค</th>
                    {timePeriods.flatMap(timePeriod => (
                        genders.map(gender => (
                            <th key={`${timePeriod}-${gender}`} colSpan={selectedDisplay === "3" ? 4 : 3}>
                                {timePeriod} - {gender}
                            </th>
                        ))
                    ))}
                    <th colSpan={selectedDisplay === "3" ? (genders.length * 4) : (genders.length * 3)}>รวม</th>
                </tr>
                <tr>
                    <th></th>
                    {timePeriods.flatMap(timePeriod => (
                        genders.flatMap(gender => (
                            <>
                                <th key={`${timePeriod}-${gender}-flag`}>สถานะการแจ้งเตือนโรคระบาด</th>
                                {selectedDisplay === "3" && <th key={`${timePeriod}-${gender}-median`}>จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี</th>}
                                <th key={`${timePeriod}-${gender}-infected`}>ยืนยันผู้ป่วย</th>
                                <th key={`${timePeriod}-${gender}-death`}>เสียชีวิต</th>
                            </>
                        ))
                    ))}
                    <>
                        {selectedDisplay === "3" && <th key={`median`}>จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี</th>}
                        <th key={`infected`}>ยืนยันผู้ป่วย</th>
                        <th key={`death`}>เสียชีวิต</th>
                    </>
                </tr>
            </thead>

            <tbody>
                {data.map(report => (
                    <tr key={report.epidem_report_id}>
                        <td>{report.epidem_report_name_th}</td>
                        {timePeriods.flatMap(timePeriod => (
                            genders.map(gender => {
                                const [year, monthOrWeek] = timePeriod.split('_');
                                const genderValue = gender === 'Male' ? 1 : 2;

                                const detail = report.details.find(d => {
                                    const isCorrectYear = d.year === parseInt(year, 10);
                                    const isCorrectGender = d.gender === genderValue;
                                    let isCorrectMonthOrWeek = true;

                                    if (hasMonthData) {
                                        isCorrectMonthOrWeek = d.month === monthOrWeek;
                                    }
                                    if (hasWeekData) {
                                        isCorrectMonthOrWeek = d.week === monthOrWeek;
                                    }

                                    return isCorrectYear && isCorrectGender && isCorrectMonthOrWeek;
                                });
                                return (
                                    <React.Fragment key={`${timePeriod}-${gender}`}>
                                        <td>{detail ? (detail.flag > 0 ? '!'.repeat(detail.flag) : '-') : '-'}</td>
                                        {selectedDisplay === "3" && renderDataCells(detail, 'median')}
                                        {renderDataCells(detail, 'infected')}
                                        {renderDataCells(detail, 'death')}
                                    </React.Fragment>
                                );
                            })
                        ))}
                        {renderTotalMedianCell(report)}
                        <td className={setColorClassInfected(Math.max(...report.details.map(d => d.color20 || 0)), Math.max(...report.details.map(d => d.color100 || 0)), selectedDisplay)}>
                            {report.totalInfected}
                        </td>
                        <td>{report.totalDeath}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default EpidemicReportTableMD5Gender;
