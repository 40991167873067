import React from 'react';
import { Paper, Typography } from '@material-ui/core';

const DiseaseNotification = () => {
  return (
    <div className='deceaseNotification'>
    <Paper style={{ flex: 1, margin: '10px', padding: '20px', textAlign: 'left' }}>
      <Typography variant="h5">สถานะการแจ้งเตือนโรคระบาด</Typography>
      <p>! = โรคที่ไม่มีสถิติในปีที่ผ่านมา</p>
      <p>!! = โรคที่มีจำนวนผู้ป่วยมากกว่าค่ามัธยฐานย้อนหลัง 5 ปี</p>
      <p>!!! = โรคอุบัติใหม่ที่ไม่เคยเกิดขึ้นจากสถิติย้อนหลัง 5 ปี</p>
    </Paper>
    </div>
  );
};

export default DiseaseNotification;
