import React, { useState, useEffect } from 'react';
import axios from 'axios';

function AlertComponent({
    selectedTime,
    searchTrigger,
    selectedYearValue,
    selectedMonthValue,
    selectedWeekValue,
    selectedFilterTag
}) {
    const [jsonData, setJsonData] = useState(null);

    const getTimeFrame = (time) => {
        if (time === "1") return "year";
        if (time === "2") return "month";
        if (time === "3") return "week";
        return "week";
    }

    useEffect(() => {
        setJsonData(null);

        const requestBody = {
            "timeFrame": getTimeFrame(selectedTime),
            "year": parseInt(selectedYearValue),
            "month": parseInt(selectedMonthValue),
            "week": parseInt(selectedWeekValue)        
        };

        axios.post('https://dev.epidemic.jumpai.tech/api/smart-notification', requestBody)
        .then(response => {
            setJsonData(response.data);
        })
        .catch(error => {
            console.error("Error fetching data:", error);
        });
    }, [searchTrigger]);

    if (!jsonData || !jsonData.data || !Array.isArray(jsonData.data) || selectedFilterTag != 'individual') {
        return null; // or return a loading spinner, or some placeholder
    }

    const renderDataCells = (item) => {
     
        if (item.isDangerousEpidemic) {
            return <td className='red'>{item.epidem_report_name_th}</td>;
        }
        else {
            return <td>{item.epidem_report_name_th}</td>;
        }
        
    };

    return (
        <div>
            {jsonData && jsonData.data && Array.isArray(jsonData.data) ? (
                <table id="epidemicReportTable">
                    <thead>
                        <tr>
                            <th>โรค</th>
                            <th>สถานะการแจ้งเตือนโรคระบาด</th>
                            <th>จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี</th>
                            <th>ยืนยันจำนวนผู้ป่วย</th>
                            <th>จำนวนผู้เสียชีวิต</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jsonData.data.map((item, index) => (
                            item.details.map((detail, detailIndex) => (
                                <tr key={`${index}-${detailIndex}`}>
                                    {renderDataCells(item)}
                                    <td>{detail.flag > 0 ? '!'.repeat(detail.flag) : '-'}</td>
                                    <td>{detail.median}</td>
                                    <td>{detail.infected}</td>
                                    <td>{detail.death}</td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Loading or no data available...</p>
            )}
        </div>
    );
    
}

export default AlertComponent;
