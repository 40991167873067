import React from 'react';

const EpidemicReportTableMD5 = ({ data, selectedDisplay }) => {
    // Check if month or week data is available
    const hasMonthData = data.some(report => report.details.some(detail => 'month' in detail));
    const hasWeekData = data.some(report => report.details.some(detail => 'week' in detail));

    // Function to create a unique identifier for each time period
    const getTimePeriod = detail => {
        if (hasWeekData && 'week' in detail) {
            const paddedWeek = String(detail.week).padStart(2, '0');
        return `${detail.year}_${paddedWeek}`;
        } else if (hasMonthData && 'month' in detail) {
            return `${detail.year}_${detail.month}`;
        } else {
            return detail.year.toString();
        }
    };

    // Parse the data to get the unique time periods and organize them
    const uniqueTimePeriods = Array.from(
        new Set(data.flatMap(report => report.details.map(getTimePeriod)))
    );

    // Prepare the headers
    const headers = uniqueTimePeriods.map(timePeriod => ({
        timePeriod,
        subHeaders: [
            'จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี',
            {
                header: 'จำนวนผู้ป่วย',
                subHeaders: ['ผู้ป่วย', 'เสียชีวิต', 'ไม่ทราบ']
            }
        ]
    }));

    function setColorClass(colormd5) {
        switch(colormd5) {
            case 1: return 'lightgrey';
            case 2: return 'green';
            case 3: return 'yellow';
            case 4: return 'orange';
            case 5: return 'red';
            default: return ''; // no additional class
        }
    }

    function setColorClassInfected(color20, color100, selectedDisplay) {
        if(selectedDisplay == "1") {
            switch(color20) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }

        if(selectedDisplay == "2") {
            switch(color100) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }
        
        return '';
    }

    const renderSubHeaders = (subHeader, timePeriod) => {
        if (selectedDisplay === "3" || subHeader !== 'จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี') {
            return <th data-column={subHeader} key={`${timePeriod}-${subHeader}`}>{subHeader}</th>;
        }
        return null;
    };

    const renderDataCells = (detail, key) => {
        if (key === 'infected') {
            return <td className={setColorClassInfected(detail.color20, detail.color100, selectedDisplay)}>{detail[key] || 0}</td>;
        } else if (selectedDisplay === "3" && key === 'median') {
            return <td className={setColorClass(detail.colormd5)}>{detail[key] || 0}</td>;
        } 
        return null;
    };

    return (
        <table id="epidemicReportTable">
            <thead>
                <tr>
                    <th>โรค</th>
                    {headers.map(header => (
                        <th key={header.timePeriod} colSpan={header.subHeaders.reduce((total, sh) => {
                            return total + (sh.subHeaders ? sh.subHeaders.length : 1 * (selectedDisplay === "3" || sh !== 'จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี' ? 1 : 0));
                        }, 0)}>
                            {header.timePeriod}
                        </th>
                    ))}
                </tr>
                <tr>
                    <th></th>
                    {headers.flatMap(header =>
                        header.subHeaders.map(subHeader => (
                            typeof subHeader === 'object' ?
                            subHeader.subHeaders.map(sh => renderSubHeaders(sh, header.timePeriod)) :
                            renderSubHeaders(subHeader, header.timePeriod)
                        ))
                    )}
                </tr>
            </thead>
            <tbody>
                {data.map(report => (
                    <tr key={report.epidem_report_id}>
                        <td>{report.epidem_report_name_th}</td>
                        {uniqueTimePeriods.map(timePeriod => {
                            const [year, month, week] = timePeriod.split('_');
                            const detail = report.details.find(d => 
                                d.year === parseInt(year, 10) && 
                                (!hasMonthData || d.month === month) &&
                                (!hasWeekData || d.week === week)) || {};
                            return (
                                <>
                                    {renderDataCells(detail, 'median')}
                                    {renderDataCells(detail, 'infected')}
                                    <td>{detail.death || 0}</td>
                                    <td>{detail.unknown || 0}</td>
                                    
                                </>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default EpidemicReportTableMD5;
