import React from 'react';

const TableSpecificDecease = ({ data, tag, selectedDisplay }) => {
  const { details } = data[0];

  // Updated function to determine the time period
  const getTimePeriod = (detail) => {
    if ('week' in detail) {
      const paddedWeek = String(detail.week).padStart(2, '0');
      return `${detail.year}_${paddedWeek}`;
    } else if ('month' in detail) {
      return `${detail.year}_${detail.month}`;
    } else {
      return `${detail.year}`;
    }
  };

  // Get all unique time periods
  const allTimePeriods = Array.from(
    new Set(details.map(getTimePeriod))
  );

  const genderMap = { '1': 'ชาย', '2': 'หญิง' };
  const tagMap = {'Gender': 'เพศ', 'Nationality': 'เชื้อชาติ', 'Area': 'พื้นที่', 'Age': 'กลุ่มอายุ'} 

  const rowData = details.reduce((acc, detail) => {
    const key = tag === "Gender" ? genderMap[detail.gender] : (detail.age_range || detail.nationality || detail.area || 'ไม่ทราบ');
    const timePeriod = getTimePeriod(detail);
    const periodData = acc[key] || {};

    periodData[timePeriod] = {
      median: detail.median,
      infected: detail.infected,
      treated: detail.treated,
      death: detail.death,
      unknown: detail.unknown,
      colormd5: detail.colormd5,
      color20: detail.color20,
      color100: detail.color100
    };

    acc[key] = periodData;
    return acc;
  }, {});

  const setColorClass = (colormd5) => {
    switch(colormd5) {
        case 1: return 'lightgrey';
        case 2: return 'green';
        case 3: return 'yellow';
        case 4: return 'orange';
        case 5: return 'red';
        default: return '';
    }
  };

  function setColorClassInfected(color20, color100, selectedDisplay) {
    if(selectedDisplay == "1") {
        switch(color20) {
            case 1: return 'lightgrey';
            case 2: return 'green';
            case 3: return 'yellow';
            case 4: return 'orange';
            case 5: return 'red';
            default: return ''; // no additional class
        }
    }

    if(selectedDisplay == "2") {
        switch(color100) {
            case 1: return 'lightgrey';
            case 2: return 'green';
            case 3: return 'yellow';
            case 4: return 'orange';
            case 5: return 'red';
            default: return ''; // no additional class
        }
    }
    
    return '';
}

  const renderDataCells = (detail, key) => {
    console.log(detail, key);
    if (key === 'infected' && detail) {
        return <td className={setColorClassInfected(detail.color20, detail.color100, selectedDisplay)}>{detail[key] || 0}</td>;
    }
    else if (selectedDisplay === "3" && detail && key === 'median') {
        return <td className={setColorClass(detail.colormd5)}>{detail[key] || 0}</td>;
    }
    else if ((key === 'death' || key === 'unknown') && detail) {
        return <td>{detail[key] || 0}</td>;
    }
    else if (selectedDisplay !== "3" && key === 'median') {
        return null;
    }
    return <td>0</td>;
};

const renderTotalMedianCell = (report) => {
    if (selectedDisplay === "3") {
        return <td className={setColorClass(Math.max(...report.details.map(d => d.colormd5 || 0)))}>
                   {report.totalMedian || 0}
               </td>;
    }
    return null;
};


  return (
    <table id="epidemicReportTable">
      <thead>
        <tr>
          <th>{tagMap[tag]}</th>
          {allTimePeriods.map(timePeriod => (
            <th colSpan={selectedDisplay === "3" ? 5 : 4} key={timePeriod}>{timePeriod}</th>
          ))}
        </tr>
        <tr>
          <th></th>
          {allTimePeriods.flatMap(timePeriod => (
            <React.Fragment key={timePeriod}>
              {selectedDisplay === "3" && <th>จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี</th>}
              <th>ยืนยันจำนวนผู้ป่วย</th>
              <th>หายจากโรค</th>
              <th>เสียชีวิต</th>
              <th>ไม่ทราบ</th>
            </React.Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.entries(rowData).map(([key, periodData]) => (
          <tr key={key}>
            <td>{key}</td>
            {allTimePeriods.map(timePeriod => (
              <React.Fragment key={timePeriod}>
                {selectedDisplay === "3" && <td className={setColorClass(periodData[timePeriod]?.colormd5)}>{periodData[timePeriod]?.median || 0}</td>}
                {renderDataCells(periodData[timePeriod], 'infected')}
                <td>{periodData[timePeriod]?.treated || 0}</td>
                <td>{periodData[timePeriod]?.death || 0}</td>
                <td>{periodData[timePeriod]?.unknown || 0}</td>
              </React.Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

};

export default TableSpecificDecease;
