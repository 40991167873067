import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link as RouterLink } from 'react-router-dom';
import CombinedEpidemicChart from './CombinedEpidemicChart';
import CompareChart from './CompareChart';
import SpecificDiseasePieChart from './SpecificDiseasePieChart';
import DropdownComponent from './DropdownComponent';
import { Button, AppBar, Tabs, Tab, Container, Toolbar, Typography, Radio, FormControlLabel, RadioGroup, Paper, Divider, withStyles, Select, MenuItem, FormControl, InputLabel, Chip  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import PatientRatio from './component/PatientRatio';
import DiseaseNotification from './component/DeceaseNotification';
import AlertComponent from './Alert';
import AlertDropdownTime from './AlertDropdownTime';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    marginBottom: theme.spacing(3),
    background: '#0f5807',
  },
  tab: {
      '&:hover': {
          backgroundColor: '#fcca33',
          color: '#0e5706',
          transition: 'background-color 0.3s'
      }
  },
  subnav: {
    backgroundColor: '#E4E4E4',
    marginBottom: theme.spacing(3),
  },
  toolbar: {
    justifyContent: 'center',
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
    textDecoration: 'none',
    color: 'inherit',
  }
}));

function MainNav() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* Image */}
      <img src="/header.jpg" alt="Header" style={{ width: '100%', height: 'auto', display: 'block' }} />

      <div style={{ 
        position: 'absolute', 
        top: '10px',
        right: '10px',
        fontSize: '16px',
        color: 'white',
        fontWeight: 'bold'
      }}>
        <button 
              style={{ 
                  background: 'none', 
                  border: 'none', 
                  color: 'white',
                  cursor: 'pointer',
                  marginRight: '5px' // space between TH and |
              }}
              onClick={() => {
                  // Handle TH click here
                  console.log('TH clicked');
              }}
          >
              Thai
          </button>
          |
          <button 
              style={{ 
                  background: 'none', 
                  border: 'none', 
                  color: 'white',
                  cursor: 'pointer',
                  marginLeft: '5px' // space between | and EN
              }}
              onClick={() => {
                  // Handle EN click here
                  console.log('EN clicked');
              }}
          >
              English
          </button>
      </div>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="ภาพรวมสถิติการเกิดโรคระบาด" component={RouterLink} to="/" className={classes.tab} />
            <Tab label="เปรียบเทียบรายโรค/กลุ่มโรคกับช่วงเวลาการระบาด" component={RouterLink} to="/compare" className={classes.tab} />
            <Tab label="แสดงรายโรคที่สนใจ" component={RouterLink} to="/interested"  className={classes.tab}/>
            <Tab label="แจ้งเตือนโรคที่ต้องสอบสวนเฉพาะราย" component={RouterLink} to="/alert" className={classes.tab} />
            <Tab label="ข่าวสารและงานวิจัยที่เกี่ยวข้อง" component={RouterLink} to="/news" className={classes.tab} />
          </Tabs>
        </Toolbar>
      </AppBar>
    </div>
    
  );
}

// Define the custom styles
const StyledTab = withStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    color: 'black',
    marginLeft: '10px',  // Add margin to the left
    marginRight: '10px', // Add margin to the right
    padding: '10px',     // Adjust padding if necessary
    border: '1px solid #ccc', // Add border for a box appearance
    borderRadius: '4px',     // Rounded corners
    "&$selected": {
      backgroundColor: 'grey',
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: 'darkgrey',
      },
    },
    "&:first-child": {
      marginLeft: 0,    // Remove the left margin for the first tab
    },
    "&:last-child": {
      marginRight: 0,   // Remove the right margin for the last tab
    }
  },
  selected: {},
}))(Tab);

const Bar = ({ title, sections, colors }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <div style={{ marginBottom: '5px', fontWeight: 'bold' }}>{title}</div>
      <div style={{ display: 'flex', width: '100%' }}>
        {sections.map((section, index) => (
          <div
            key={index}
            style={{
              backgroundColor: colors[index],
              color: 'black',
              padding: '5px',
              textAlign: 'center',
              fontSize: '14px',
              flex: `1 0 0%`, // Each section takes equal space
            }}
          >
            {section}
          </div>
        ))}
      </div>
    </div>
  );
};


function SubNav({
  setSelectedFilterTag
}) {
  const [selectedTab, setSelectedTab] = React.useState(0);


  const handleTabChange = (event, newValue) => {
    console.log(newValue);
    const filterTags = ['all', 'gender', 'age', 'nationality',  'area']
    setSelectedTab(newValue);
    setSelectedFilterTag(filterTags[newValue]);
  };

  return (
    <Container>
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <StyledTab label="ภาพรวม" component={RouterLink} to="#overview" />
        <StyledTab label="เพศ" component={RouterLink} to="#gender"/>
        <StyledTab label="กลุ่มอายุ" component={RouterLink} to="#age"/>
        <StyledTab label="เชื้อชาติ" component={RouterLink} to="#nationality"/>
        <StyledTab label="พื้นที่" component={RouterLink} to="#area"/>
      </Tabs>
    </Container>
  );
}

function SubNavInterested({
  setSelectedFilterTag
}) {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    const filterTags = ['gender', 'age', 'nationality',  'area']
    setSelectedTab(newValue);
    setSelectedFilterTag(filterTags[newValue]);
  };

  return (
    <Container>
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <StyledTab label="เพศ" component={RouterLink} to="#gender" />
        <StyledTab label="กลุ่มอายุ" component={RouterLink} to="#age" />
        <StyledTab label="เชื้อชาติ" component={RouterLink} to="#nationality" />
        <StyledTab label="พื้นที่" component={RouterLink} to="#area" />
      </Tabs>
    </Container>
  );
}

function SubNavAlert({
  setSelectedFilterTag
}) {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    const filterTags = ['individual', 'epidemic']
    setSelectedTab(newValue);
    setSelectedFilterTag(filterTags[newValue]);
  };

  return (
    <Container>
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <StyledTab label="แจ้งเตือนโรคที่ต้องสอบสวนเฉพาะราย" component={RouterLink} to="#individual" />
        <StyledTab label="แจ้งเตือนแนวโน้มโรคที่มีอัตราการระบาดสูง" component={RouterLink} to="#epidemic" />
      </Tabs>
    </Container>
  );
}

const Home = () => {
  const [selectedYearValue, setSelectedYearValue] = useState('');
  const [selectedWeekValue, setSelectedWeekValue] = useState('');
  const [selectedEndYearValue, setSelectedEndYearValue] = useState('');
  const [selectedEndWeekValue, setSelectedEndWeekValue] = useState('');
  const [selectedMonthValue, setSelectedMonthValue] = useState('');
  const [selectedEndMonthValue, setSelectedEndMonthValue] = useState('');
  const [selectedTime, setSelectedTime] = useState("1");
  const [selectedOutputType, setSelectedOutputType] = useState("1");
  const [selectedFilterTag, setSelectedFilterTag] = useState("all")
  const [searchTrigger, setSearchTrigger] = useState(0);
  const [selectedDisplay, setSelectedDisplay] = useState("3");
  const [selectedCompareOption, setSelectedCompareOption] = useState("1");

  return (
    <div style={{ width: '100%', margin: '0 auto' }}> {/* This container has a maximum width of 1200px and is centered */}
      <SubNav setSelectedFilterTag={setSelectedFilterTag} style={{ flex: 1 }} /> 
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Section1 
          selectedOutputType={selectedOutputType}
          setSelectedOutputType={setSelectedOutputType}
        />
        <Section2 
          selectedYearValue={selectedYearValue}
          setSelectedYearValue={setSelectedYearValue}
          selectedWeekValue={selectedWeekValue}
          setSelectedWeekValue={setSelectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          setSelectedEndYearValue={setSelectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          setSelectedEndWeekValue={setSelectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          setSelectedMonthValue={setSelectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          setSelectedEndMonthValue={setSelectedEndMonthValue}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          selectedFilterTag={selectedFilterTag}
          setSearchTrigger={setSearchTrigger}
          selectedCompareOption={selectedCompareOption}
          setSelectedCompareOption={setSelectedCompareOption}
        />
        <Section3 selectedDisplay={selectedDisplay} setSelectedDisplay={setSelectedDisplay}/>
        {/* <PatientRatio/> */}
        <DiseaseNotification/>
      </div>
      <div style={{ flex: 1 }}>
        <CombinedEpidemicChart 
          selectedYearValue={selectedYearValue}
          selectedWeekValue={selectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          selectedTime={selectedTime}
          selectedOutputType={selectedOutputType}
          selectedFilterTag={selectedFilterTag}
          searchTrigger={searchTrigger}
          selectedDisplay={selectedDisplay}
          selectedCompareOption={selectedCompareOption}
        />
      </div>
    </div>
  );
};

const Compare = () => {
  const [selectedYearValue, setSelectedYearValue] = useState('');
  const [selectedWeekValue, setSelectedWeekValue] = useState('');
  const [selectedEndYearValue, setSelectedEndYearValue] = useState('');
  const [selectedEndWeekValue, setSelectedEndWeekValue] = useState('');
  const [selectedMonthValue, setSelectedMonthValue] = useState('');
  const [selectedEndMonthValue, setSelectedEndMonthValue] = useState('');
  const [selectedTime, setSelectedTime] = useState("1");
  const [selectedOutputType, setSelectedOutputType] = useState("1");
  const [epidemSelected, setEpidemSelected] = useState([]);
  const [searchTrigger, setSearchTrigger] = useState(0);
  const [selectedDisplay, setSelectedDisplay] = useState("3");

  return (
    <div style={{ width: '100%', margin: '0 auto' }}> {/* This container has a maximum width of 1200px and is centered */}

      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Section1 
          selectedOutputType={selectedOutputType}
          setSelectedOutputType={setSelectedOutputType}
          canSelectDisease={true}
          epidemSelected={epidemSelected}
          setEpidemSelected={setEpidemSelected}
          multiple={true}
        />
        <Section2 
          selectedYearValue={selectedYearValue}
          setSelectedYearValue={setSelectedYearValue}
          selectedWeekValue={selectedWeekValue}
          setSelectedWeekValue={setSelectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          setSelectedEndYearValue={setSelectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          setSelectedEndWeekValue={setSelectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          setSelectedMonthValue={setSelectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          setSelectedEndMonthValue={setSelectedEndMonthValue}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          setSearchTrigger={setSearchTrigger}
        />
        <Section3 selectedDisplay={selectedDisplay} setSelectedDisplay={setSelectedDisplay}/>
        {/* <PatientRatio/> */}
        <DiseaseNotification/>
      </div>
      <div style={{ flex: 1 }}>
        <CompareChart 
          selectedYearValue={selectedYearValue}
          selectedWeekValue={selectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          selectedTime={selectedTime}
          selectedOutputType={selectedOutputType}
          epidemSelected={epidemSelected}
          searchTrigger={searchTrigger}
          selectedDisplay={selectedDisplay}
        />
      </div>
    </div>
  );
};

const Interested = () => {
  const [selectedYearValue, setSelectedYearValue] = useState('');
  const [selectedWeekValue, setSelectedWeekValue] = useState('');
  const [selectedEndYearValue, setSelectedEndYearValue] = useState('');
  const [selectedEndWeekValue, setSelectedEndWeekValue] = useState('');
  const [selectedMonthValue, setSelectedMonthValue] = useState('');
  const [selectedEndMonthValue, setSelectedEndMonthValue] = useState('');
  const [selectedTime, setSelectedTime] = useState("1");
  const [selectedOutputType, setSelectedOutputType] = useState("1");
  const [epidemSelected, setEpidemSelected] = useState([]);
  const [selectedFilterTag, setSelectedFilterTag] = useState("gender")
  const [searchTrigger, setSearchTrigger] = useState(0);
  const [selectedDisplay, setSelectedDisplay] = useState("3");

  return (
    <div style={{ width: '100%', margin: '0 auto' }}> {/* This container has a maximum width of 1200px and is centered */}
      <SubNavInterested setSelectedFilterTag={setSelectedFilterTag} style={{ flex: 1 }} /> 
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Section1 
          selectedOutputType={selectedOutputType}
          setSelectedOutputType={setSelectedOutputType}
          canSelectDisease={true}
          epidemSelected={epidemSelected}
          setEpidemSelected={setEpidemSelected}
          disableRadio={true}
          multiple={false}
        />
        <Section2 
          selectedYearValue={selectedYearValue}
          setSelectedYearValue={setSelectedYearValue}
          selectedWeekValue={selectedWeekValue}
          setSelectedWeekValue={setSelectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          setSelectedEndYearValue={setSelectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          setSelectedEndWeekValue={setSelectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          setSelectedMonthValue={setSelectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          setSelectedEndMonthValue={setSelectedEndMonthValue}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          setSearchTrigger={setSearchTrigger}
        />
        <Section3 selectedDisplay={selectedDisplay} setSelectedDisplay={setSelectedDisplay}/>
        {/* <PatientRatio/> */}
      </div>
      <div style={{ flex: 1 }}>
        <SpecificDiseasePieChart 
          selectedYearValue={selectedYearValue}
          selectedWeekValue={selectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          selectedTime={selectedTime}
          selectedOutputType={selectedOutputType}
          selectedFilterTag={selectedFilterTag}
          searchTrigger={searchTrigger}
          epidemSelected={epidemSelected}
          selectedDisplay={selectedDisplay}
        />
      </div>
    </div>
  );
};
// const Interested = () => <div>แสดงรายโรคที่สนใจ</div>;
const Alert = () => {
  const [selectedFilterTag, setSelectedFilterTag] = useState("individual");
  const [selectedTime, setSelectedTime] = useState("1");
  const [searchTrigger, setSearchTrigger] = useState(0);
  const currentDateInfo = getCurrentDateInfo();
  const [selectedYearValue, setSelectedYearValue] = useState(currentDateInfo.year);
  const [selectedWeekValue, setSelectedWeekValue] = useState(currentDateInfo.month);
  const [selectedMonthValue, setSelectedMonthValue] = useState(currentDateInfo.week);

  function getCurrentDateInfo() {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const pastDaysOfYear = (now - startOfYear) / 86400000; // 86400000 milliseconds in a day

    return {
        year: now.getFullYear(),
        month: now.getMonth() + 1, // January is 0
        week: Math.ceil(pastDaysOfYear / 7)
    };
  }

  return (
    <div style={{ width: '100%', margin: '0 auto' }}> 
      <SubNavAlert setSelectedFilterTag={setSelectedFilterTag} style={{ flex: 1 }} /> 
      
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <DiseaseNotification/>
        <SectionAlert selectedTime={selectedTime} setSelectedTime={setSelectedTime} setSearchTrigger={setSearchTrigger}
        setSelectedYearValue={setSelectedYearValue} setSelectedWeekValue={setSelectedWeekValue} setSelectedMonthValue={setSelectedMonthValue} 
        selectedYearValue={selectedYearValue} selectedWeekValue={selectedWeekValue} selectedMonthValue={selectedMonthValue} />
      </div>

      <div style={{ flex: 1 }}>
        <AlertComponent
          selectedTime={selectedTime}
          searchTrigger={searchTrigger}
          selectedYearValue={selectedYearValue} 
          selectedWeekValue={selectedWeekValue} 
          selectedMonthValue={selectedMonthValue}
          selectedFilterTag={selectedFilterTag}
        />
      </div>
    </div>
  );
};

const News = () => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [diseaseData, setDiseaseData] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [showIframe, setShowIframe] = useState(false);

    useEffect(() => {
    // loadGoogleTrendsScript();
  }, []);

  const loadGoogleTrendsScript = () => {
    if (!window.trends) {
      const script = document.createElement('script');
      script.src = 'https://ssl.gstatic.com/trends_nrtr/3523_RC02/embed_loader.js';
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        renderAllGoogleTrendsWidgets();
      };
    } else {
      renderAllGoogleTrendsWidgets();
    }
  };

  const renderGoogleTrendsWidget = (type, keyword, geo, time, containerId) => {
    window.trends.embed.renderExploreWidget(type, {
      "comparisonItem": [{"keyword": keyword, "geo": geo, "time": time}],
      "category": 0,
      "property": ""
    }, {
      "exploreQuery": `geo=${geo}&q=${keyword}&hl=en&date=${time}`,
      "guestPath": "https://trends.google.com:443/trends/embed/"
    }, document.getElementById(containerId));
  };

  // Function to render all Google Trends widgets
  const renderAllGoogleTrendsWidgets = () => {
    renderGoogleTrendsWidget("TIMESERIES", "epidemic", "TH", "today 12-m", "widget-timeseries");
    renderGoogleTrendsWidget("GEO_MAP", "epidemic", "TH", "today 12-m", "widget-geomap");
    // Add more widgets as needed
  };

  const renderTrendsScript = (widgetType, keyword, geo, time, containerId) => {
    return {
      __html: `
        trends.embed.renderExploreWidget("${widgetType}", {
          "comparisonItem": [{"keyword": "${keyword}", "geo": "${geo}", "time": "${time}"}],
          "category": 0,
          "property": ""
        }, {
          "exploreQuery": "geo=${geo}&q=${keyword}&hl=en&date=${time}",
          "guestPath": "https://trends.google.com:443/trends/embed/"
        }, document.getElementById("${containerId}"));
      `
    };
  };

  // Load the Google Trends script when the component mounts
  // useEffect(() => {
  //   loadGoogleTrendsScript();
  // }, []);

  const handleChangeIframe = (event) => {
    setShowIframe(false);
    setSelectedValue(event.target.value);
  };

    const getIframeSrc = () => {
      const baseUrls = {
          'bing': "https://bing.com/search?q=",
          'webcrawler': "https://www.webcrawler.com/serp?q=",
          'google': "https://www.google.com/search?q=",
          'sd': "https://www.sciencedirect.com/search?qs=",
          'pubmed': "https://pubmed.ncbi.nlm.nih.gov/?term=",
          'gs': "https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&btnG=&q="
      };
      return baseUrls[selectedValue] || '';
  };

  useEffect(() => {
    axios.get('https://dev.epidemic.jumpai.tech/api/epidemic', {
        headers: {
            'Authorization': 'ya29.a0AfB_byClA5ly7MsmkrDp-tETkJ4ZrJUP_Gh7sNhANQUIV7QsUhDBO79HPA8Ukg1oiN10Sl1Zw1Fonw3hUBcNCA_vK0Z95k5zyXLTf4SpfCJcCgLyDc1dNShndjsXZSDoqIDASt7HJsTMqTLCNDxi0bDeyF7ozzUJUdW9aCgYKARkSARESFQGOcNnCzndUPp9S-7xiD1wMRErtsw0171'
        }
    })
    .then(response => {
        if (response.data && Array.isArray(response.data.data)) {
            setDiseaseData(response.data.data);
        }
    })
    .catch(error => {
        console.error("Error fetching disease data:", error);
    });
  }, []);

  const handleChange = (event) => {
      setShowIframe(false);
      setSelectedValues(event.target.value);
  };

  const handleButtonClick = () => {
    const url = getIframeSrc(selectedValue);
    if (selectedValue == "bing" || selectedValue == "webcrawler"){
      setShowIframe(true);
    }
    else if (url) {
        // Construct the full URL with the selected value for searching
        const fullUrl = `${url}${encodeURIComponent(selectedValues.join(' '))}`;
        window.open(fullUrl, '_blank');
    }
};

  const queryString = selectedValues.length > 0 ? '' + selectedValues.join(' ') : '';

  return (
    <div>
      <div style={{ width: '100%', margin: '0 auto' }}> 
          
          {/* Material-UI Multi-Select */}
          <FormControl variant="outlined" style={{ minWidth: 400, marginBottom: '20px' }}>
            <InputLabel id="news-mutiple-chip-label">เลือกโรคที่ต้องการค้นหา</InputLabel>
            <Select
                labelId="news-mutiple-chip-label"
                id="news-mutiple-chip"
                multiple
                value={selectedValues}
                onChange={handleChange}
                renderValue={(selected) => (
                    <div>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </div>
                )}
            >
                {diseaseData.map((disease, index) => (
                    <MenuItem key={index} value={disease.epidem_report_name_en}>{disease.epidem_report_name_en}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ minWidth: 400, marginBottom: '20px' }}>
                <InputLabel id="news-single-select-label">เลือกบริการค้นหา</InputLabel>
                <Select
                    labelId="news-single-select-label"
                    id="news-single-select"
                    value={selectedValue}
                    onChange={handleChangeIframe}
                    label="เลือกบริการค้นหา"
                >
                    <MenuItem value="bing">Bing</MenuItem>
                    <MenuItem value="webcrawler">WebCrawler</MenuItem>
                    <MenuItem value="google">Google</MenuItem>
                    <MenuItem value="gs">Google Scholar</MenuItem>
                    {/* <MenuItem value="sd">ScienceDirect</MenuItem> */}
                    <MenuItem value="pubmed">PubMed</MenuItem>
                    
                </Select>
          </FormControl>

          <Button
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
                disabled={!selectedValues || !selectedValue}
            >
                Search
            </Button>

      {selectedValue && showIframe && (selectedValue === 'bing' || selectedValue === 'webcrawler') && (
        <iframe 
          src={`${getIframeSrc()}${queryString}`} 
          width="100%" 
          height="800px" 
          frameBorder="0"
          title="Search Iframe"
        ></iframe>
      )}

      
      </div>
      {/* Google Trends widgets containers */}
      {/* <div id="widget-timeseries" className="trends-widget"></div>
      <div id="widget-geomap" className="trends-widget"></div> */}

     
      </div>
  );
};


const Section1 = (props) => {
  const [diseaseData, setDiseaseData] = useState([]);

  useEffect(() => {
    axios.get('https://dev.epidemic.jumpai.tech/api/epidemic', {
        headers: {
            'Authorization': 'ya29.a0AfB_byClA5ly7MsmkrDp-tETkJ4ZrJUP_Gh7sNhANQUIV7QsUhDBO79HPA8Ukg1oiN10Sl1Zw1Fonw3hUBcNCA_vK0Z95k5zyXLTf4SpfCJcCgLyDc1dNShndjsXZSDoqIDASt7HJsTMqTLCNDxi0bDeyF7ozzUJUdW9aCgYKARkSARESFQGOcNnCzndUPp9S-7xiD1wMRErtsw0171'
        }
    })
    .then(response => {
        if (response.data && Array.isArray(response.data.data)) {
            setDiseaseData(response.data.data);
            console.log(diseaseData)
            if(props.selectedOutputType === "2") {
              const uniqueDiseases = [];
              const uniqueIds = [];
          
              response.data.data.forEach(disease => {
                  if (!uniqueIds.includes(disease.epidem_group_id)) {
                      uniqueDiseases.push(disease);
                      uniqueIds.push(disease.epidem_group_id);
                  }
              });
          
              setDiseaseData(uniqueDiseases);
          }
            
        }
    })
    .catch(error => {
        console.error("Error fetching disease data:", error);
    });
  }, [props.selectedOutputType]);

  const handleRadioChange = (event) => {
    props.setSelectedOutputType(event.target.value);
    if (props.canSelectDisease){
      props.setEpidemSelected([]);
    }
    
  };

  const handleSelectChange = (event) => {
    props.setEpidemSelected(event.target.value);
  };

  return (
    <Paper style={{ flex: 1, margin: '0 10px', padding: '20px', textAlign: 'center' }}>
      <Typography variant="h5">เลือกรูปแบบการแสดงผลของข้อมูล</Typography>
      <Divider style={{ margin: '10px 0' }} />
      <RadioGroup name="display" value={props.selectedOutputType} onChange={handleRadioChange}>
        <FormControlLabel value="1" control={<Radio />} label="แสดงผลแบบรายโรค" />
        <FormControlLabel value="2" control={<Radio />} label="แสดงผลแบบแบ่งตามกลุ่มโรค" disabled={props.disableRadio} />
      </RadioGroup>
      {props.canSelectDisease && (
        <>
          <Divider style={{ margin: '10px 0' }} />
          <FormControl variant="outlined" style={{ minWidth: 400, marginBottom: '20px' }}>
            <InputLabel id="news-mutiple-chip-label">เลือกโรคที่ต้องการค้นหา</InputLabel>
            <Select
                labelId="news-mutiple-chip-label"
                id="news-mutiple-chip"
                multiple={props.multiple}
                value={props.epidemSelected}
                onChange={handleSelectChange}
                renderValue={(selected) => {
                  // When multiple is false, selected is not an array, handle it as a single value
                  if (props.multiple === false) {
                      const disease = props.selectedOutputType === "1"
                          ? diseaseData.find(disease => disease['epidem_report_id'] === selected)
                          : diseaseData.find(disease => disease['epidem_group_id'] === selected);
          
                      const label = props.selectedOutputType === "1"
                          ? disease?.['epidem_report_name_th'] // Use optional chaining in case the find returns undefined
                          : disease?.['epidem_group_name_th'];
          
                      return <Chip key={selected} label={label || ''} />;
                  } else {
                      // When multiple is true, selected is an array, handle it as such
                      return (
                          <div>
                              {selected.map((value) => {
                                  const disease = props.selectedOutputType === "1"
                                      ? diseaseData.find(disease => disease['epidem_report_id'] === value)
                                      : diseaseData.find(disease => disease['epidem_group_id'] === value);
          
                                  const label = props.selectedOutputType === "1"
                                      ? disease?.['epidem_report_name_th']
                                      : disease?.['epidem_group_name_th'];
          
                                  return <Chip key={value} label={label || ''} />;
                              })}
                          </div>
                      );
                  }
              }}
            >
                {diseaseData.map((disease, index) => (
                    <MenuItem key={index} value={props.selectedOutputType == "1" ? disease.epidem_report_id : disease.epidem_group_id}>{props.selectedOutputType == "1" ? disease.epidem_report_name_th : disease.epidem_group_name_th
                  }</MenuItem>
                ))}
            </Select>
        </FormControl>
        </>
      )}
    </Paper>
  );
}

const Section2 = (props) => {
  
  const handleRadioChange = (event) => {
    props.setSelectedTime(event.target.value);
    props.setSelectedYearValue('')
    props.setSelectedWeekValue('')
    props.setSelectedMonthValue('')
    props.setSelectedEndYearValue('')
    props.setSelectedEndWeekValue('')
    props.setSelectedEndMonthValue('')
  };

  const handleRadioChangeOption = (event) => {
    props.setSelectedCompareOption(event.target.value);
  };

  const handleSearchClick = (event) => {
    props.setSearchTrigger(prev => prev + 1);
  };

  return (
    <Paper style={{ flex: 1, margin: '10px', padding: '20px', textAlign: 'center' }}>
      <Typography variant="h5">การกำหนดช่วงเวลา</Typography>
      <Divider style={{ margin: '10px 0' }} />
      <Typography variant="h6">เลือกรูปแบบการกำหนดช่วงเวลา</Typography>
      <RadioGroup name="time" value={props.selectedTime} onChange={handleRadioChange} row> 
        <FormControlLabel value="1" control={<Radio />} label="รายปี" />
        <FormControlLabel value="2" control={<Radio />} label="รายเดือน" />
        <FormControlLabel value="3" control={<Radio />} label="รายสัปดาห์" />
      </RadioGroup>
      <Divider style={{ margin: '10px 0' }} />
      <Typography variant="h6">ข้อมูล</Typography>
      <RadioGroup name="datarate" value={props.selectedCompareOption} defaultValue="1" onChange={handleRadioChangeOption} style={{minWidth: '380px'}} row>
        <FormControlLabel value="1" control={<Radio />} label="ข้อมูลแบบต่อเนื่อง" />
        <FormControlLabel value="2" control={<Radio />} label="ข้อมูลแบบเทียบเคียง"/>
      </RadioGroup>
      <DropdownComponent 
        selectedYearValue={props.selectedYearValue}
        onYearChange={props.setSelectedYearValue}
        selectedWeekValue={props.selectedWeekValue}
        onWeekChange={props.setSelectedWeekValue}
        selectedEndYearValue={props.selectedEndYearValue}
        onEndYearChange={props.setSelectedEndYearValue}
        selectedEndWeekValue={props.selectedEndWeekValue}
        onEndWeekChange={props.setSelectedEndWeekValue}
        selectedMonthValue={props.selectedMonthValue}
        onMonthChange={props.setSelectedMonthValue}
        selectedEndMonthValue={props.selectedEndMonthValue}
        onEndMonthChange={props.setSelectedEndMonthValue}
        selectedTime={props.selectedTime}
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSearchClick} // You will need to define this function to perform the search
        style={{ marginTop: '20px' }}
      >
        ค้นหา
    </Button>
    </Paper>
  );
}

const Section3 = (props) => {

  const handleRadioChange = (event) => {
    props.setSelectedDisplay(event.target.value);
  }

  return (
    <Paper style={{ flex: 1, margin: '0 10px', padding: '20px', textAlign: 'center' }}>
      <Typography variant="h5">เลือกรูปแบบการแสดงสถานะการระบาด</Typography>
      <Divider style={{ margin: '10px 0' }} />
      <RadioGroup name="dp1" defaultValue={props.selectedDisplay} onChange={handleRadioChange} >
        <FormControlLabel value="1" control={<Radio />} label="จำนวนผู้ป่วยต่อประชากร 100,000 คน (>20)" />
        <Bar    
          // title="อัตราผู้ป่วยต่อประชากร 100,000 คน (> 20)"
          sections={['0', '0-5', '5-10', '10-20', '> 20']}
          colors={['lightgray', '#30bf30', '#ffe414', 'orange', 'red']}
        />
        <FormControlLabel value="2" control={<Radio />} label="จำนวนผู้ป่วยต่อประชากร 100,000 คน (>100)" />
        <Bar
          // title="อัตราผู้ป่วยต่อประชากร 100,000 คน (> 100)"
          sections={['0', '0-50', '50-100', '> 100']}
          colors={['lightgray', '#30bf30', '#ffe414', 'red']}
        />
        <FormControlLabel value="3" control={<Radio />} label="จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี" />
        <Bar
          // title="จำนวนผู้ป่วยเทียบ (m = มัธยฐาน 5 ปี)"
          sections={['0', '< m', '= m', '> m']}
          colors={['lightgray', '#30bf30', '#ffe414', 'red']}
        />
      </RadioGroup>
    </Paper>
  );
}

const SectionAlert = (props) => {
  
  const handleRadioChange = (event) => {
    props.setSelectedTime(event.target.value);
  };

  const handleSearchClick = (event) => {
    props.setSearchTrigger(prev => prev + 1);
  };

  return (
    <Paper style={{ flex: 1, margin: '10px', padding: '20px', textAlign: 'center' }}>
      <Typography variant="h5">การกำหนดช่วงเวลา</Typography>
      <Divider style={{ margin: '10px 0' }} />
      <Typography variant="h6">เลือกรูปแบบการกำหนดช่วงเวลา</Typography>
      <RadioGroup name="time" value={props.selectedTime} onChange={handleRadioChange} row> 
        <FormControlLabel value="1" control={<Radio />} label="รายปี" />
        <FormControlLabel value="2" control={<Radio />} label="รายเดือน" />
        <FormControlLabel value="3" control={<Radio />} label="รายสัปดาห์" />
      </RadioGroup>
      <Divider style={{ margin: '10px 0' }} />
      <AlertDropdownTime 
        selectedTime={props.selectedTime}
        setSelectedYearValue={props.setSelectedYearValue} setSelectedWeekValue={props.setSelectedWeekValue} setSelectedMonthValue={props.setSelectedMonthValue} 
        selectedYearValue={props.selectedYearValue} selectedWeekValue={props.selectedWeekValue} selectedMonthValue={props.selectedMonthValue}
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSearchClick} // You will need to define this function to perform the search
        style={{ marginTop: '20px' }}
      >
        ค้นหา
    </Button>
    </Paper>
  );
}

function checkAndRedirect() {
  const postUrl = 'https://dev.epidemic.jumpai.tech/api/epidemic'; 

  axios.get(postUrl)
  .then(response => {
    console.log('Success:', response.data);
  })
  .catch(error => {
    if (error.response && error.response.status === 401) {
      const redirectUrl = 'https://dev.epidemic.jumpai.tech/api/auth/google/login';
      console.log(`Redirecting to: ${redirectUrl}`);
      // Perform your redirection logic here if needed
      window.location.href = redirectUrl;
    } else {
      console.error('Error:', error.message);
    }
  });
}


function App() {
  const classes = useStyles();

  checkAndRedirect();
  
  return (
    <Router>
      <div className={classes.root}>
        <MainNav />
        <Container maxWidth={false}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/compare" element={<Compare />} />
            <Route path="/interested" element={<Interested />} />
            <Route path="/alert" element={<Alert />} />
            <Route path="/news" element={<News />} />
          </Routes>
        </Container>
      </div>
    </Router>
  );
}

export default App;
