import React ,{ useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import EpidemicReportTableMD5 from './component/EpidemicReportTableMD5'
import EpidemicReportTableMD5Gender from './component/EpidemicReportTableMD5Gender'
import EpidemicReportTableMD5Other from './component/EpidemicReportTableMD5Other'
import EpidemicReportTableMD5Group from './component/EpidemicReportTableMD5Group'
import EpidemicReportTableMD5GroupGender from './component/EpidemicReportTableMD5GroupGender'
import EpidemicReportTableMD5GroupDynamic from './component/EpidemicReportTableMD5GroupDynamic'

function CombinedEpidemicChart({
    selectedYearValue, 
    selectedWeekValue,
    selectedMonthValue, 
    selectedEndYearValue, 
    selectedEndWeekValue,
    selectedEndMonthValue,
    selectedTime,
    selectedOutputType,
    selectedFilterTag,
    searchTrigger,
    selectedDisplay,
    selectedCompareOption
}) {
    const [jsonData, setJsonData] = useState(null);
    const [jsonData2, setJsonData2] = useState(null);
    const [customLegend, setCustomLegend] = useState("");

    const updateCustomLegend = () => {
        const legendHtml = `
            <div style="text-align: center;">
                <span style="display: inline-block; margin-right: 10px;">
                    <span style="background-color: darkgrey; width: 20px; height: 20px; display: inline-block;"></span> Infected
                </span>
                <span style="display: inline-block; margin-right: 10px;">
                    <span style="background-color: lightblue; width: 20px; height: 20px; display: inline-block;"></span> Infected Predict
                </span>
                <span style="display: inline-block;">
                    <span style="background-color: lightgray; width: 20px; height: 20px; display: inline-block;"></span> Deaths
                </span>
                <span style="display: inline-block;">
                    <span style="background-color: darkred; width: 20px; height: 20px; display: inline-block;"></span> Deaths Predict
                </span>
            </div>
        `;
        setCustomLegend(legendHtml);
    };
    

    const getTimeFrame = (time) => {
        if (time === "1") return "year";
        if (time === "2") return "month";
        if (time === "3") return "week";
        return "week";  // default value
    }    

    const getOutputType = (type) => {
        return type === "1" ? "indiv" : "group";
    };

    const genders = ['', 'Male', 'Female'];

    useEffect(() => {
        updateCustomLegend();
    }, []); // Empty dependency array to run only once on mount
    

    const getGroupDetail = async (groupId) => {
        const requestBody = {
            "timeFrame": getTimeFrame(selectedTime),    // year, month, week
            "startYear": parseInt(selectedYearValue),
            "startWeek": parseInt(selectedWeekValue),
            "startMonth": parseInt(selectedMonthValue),
            "endYear": parseInt(selectedEndYearValue),
            "endWeek": parseInt(selectedEndWeekValue),
            "endMonth": parseInt(selectedEndMonthValue),
            "filterTag": selectedFilterTag,    // all, gender, nationality, age, or area
            "outputType": "indiv",   // indiv or group
            "filterEpidemByGroupId": groupId,
            "isCompareOption": selectedCompareOption == "2"
        };
    
        try {
            const response = await axios.post('https://dev.epidemic.jumpai.tech/api/overviews', requestBody);
            return {
                data: response.data
            };
        } catch (error) {
            console.error('Error fetching group details:', error);
            return {
                data: `Details for group ${groupId}`
            };
        }
    };

    useEffect(() => {
        setJsonData(null);
        setJsonData2(null);
        const requestBody = {
            "timeFrame": getTimeFrame(selectedTime),    // year, month, week
            "startYear": parseInt(selectedYearValue),
            "startWeek": parseInt(selectedWeekValue),
            "startMonth": parseInt(selectedMonthValue),
            "endYear": parseInt(selectedEndYearValue),
            "endWeek": parseInt(selectedEndWeekValue),
            "endMonth": parseInt(selectedEndMonthValue),
            "filterTag": selectedFilterTag,    // all, gender, nationality, age, or area
            "outputType": getOutputType(selectedOutputType),   // indiv or group
            "isCompareOption": selectedCompareOption == "2"
        };

        if (parseInt(selectedYearValue) > 0) {
            axios.post('https://dev.epidemic.jumpai.tech/api/overviews/total-infected', requestBody)
            .then(response => {
                const data = {"data": [{"details": response.data.data}]}
                setJsonData2(data);   
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });

            axios.post('https://dev.epidemic.jumpai.tech/api/overviews', requestBody)
            .then(response => {
                setJsonData(response.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
        }
        
        // Fetch data from the API when the component mounts
        
    }, [searchTrigger]);

    let weeks = [];
    let infectedCounts = [];
    let deathCounts = [];
    let groupIds = [];
    let groupNameMap = {};
    let weeks2 = [];
    let infectedCounts2 = [];
    let deathCounts2 = [];
    let groupIds2 = [];
    let groupNameMap2 = {};

    let infectedBackgroundColors = [];
    let infectedBackgroundColors2 = [];

    
    if (!jsonData || !jsonData.data || !Array.isArray(jsonData.data)) {
        return null; // or return a loading spinner, or some placeholder
    }

    if (!jsonData2 || !jsonData2.data || !Array.isArray(jsonData2.data)) {
        return null; // or return a loading spinner, or some placeholder
    }
    
    jsonData.data.forEach(group => {
        // Sample last 3 weeks
        const lastThreeWeeks = group.details;
        
        lastThreeWeeks.forEach(detail => {
            if (getOutputType(selectedOutputType) === "indiv") {
                if (selectedTime === "1") {
                    weeks.push(`${detail.year}`);
                }
                else if (selectedTime === "2") {
                    weeks.push(`${detail.year}/${detail.month}`);
                }
                else {
                    weeks.push(`${detail.year}/${detail.week}`);
                }
            } else {
                if (selectedTime === "1") {
                    if (selectedFilterTag == "gender") {
                        weeks.push(`${detail.year}_${genders[detail.gender]}`);
                    } else if (selectedFilterTag == "age"){
                        weeks.push(`${detail.year}_${detail.age_range}`);
                    } else if (selectedFilterTag == "nationality"){
                        weeks.push(`${detail.year}_${detail.nationality}`);
                    } else if (selectedFilterTag == "area"){
                        weeks.push(`${detail.year}_${detail.area}`);
                    }
                    else {
                        weeks.push(`${detail.year}`);
                    }
                    
                }
                else if (selectedTime === "2") {
                    weeks.push(`${detail.year}/${detail.month}`);
                }
                else {
                    weeks.push(`${detail.year}/${detail.week}`);
                }
            }
            
            infectedCounts.push(detail.infected);
            if (detail.isPrediction) {
                infectedBackgroundColors.push('lightblue'); // Color for predicted infected data
                deathCounts.push({ value: detail.death, isPrediction: true });
            } else {
                infectedBackgroundColors.push('grey'); // Default color for actual infected data
                deathCounts.push({ value: detail.death, isPrediction: false });
            }
        });

        const name_th = group.epidem_report_name_th == undefined ? group.epidem_group_name_th : group.epidem_report_name_th;
        const gid = group.epidem_report_id == undefined ? group.epidem_group_id : group.epidem_report_id;
        groupNameMap[gid] = name_th;
        groupIds.push(gid);

        // Add spacing for visual separation between groups
        weeks.push('');
        infectedCounts.push(0);
        infectedBackgroundColors.push('rgba(0,0,0,0)'); // Transparent for spacing
    });

    jsonData2.data.forEach(group => {
        // Sample last 3 weeks
        const lastThreeWeeks = group.details;
        
        lastThreeWeeks.forEach(detail => {
            if (getOutputType(selectedOutputType) === "indiv") {
                if (selectedTime === "1") {
                    if (selectedFilterTag == "gender") {
                        weeks2.push(`${detail.year}_${genders[detail.gender]}`);
                    } else if (selectedFilterTag == "age"){
                        weeks2.push(`${detail.year}_${detail.age_range}`);
                    } else if (selectedFilterTag == "nationality"){
                        weeks2.push(`${detail.year}_${detail.nationality}`);
                    } else if (selectedFilterTag == "area"){
                        weeks2.push(`${detail.year}_${detail.area}`);
                    }
                    else {
                        weeks2.push(`${detail.year}`);
                    }
                }
                else if (selectedTime === "2") {
                    weeks2.push(`${detail.year}/${detail.month}`);
                }
                else {
                    weeks2.push(`${detail.year}/${detail.week}`);
                }
            } else {
                if (selectedTime === "1") {
                    if (selectedFilterTag == "gender") {
                        weeks2.push(`${detail.year}_${genders[detail.gender]}`);
                    } else if (selectedFilterTag == "age"){
                        weeks2.push(`${detail.year}_${detail.age_range}`);
                    } else if (selectedFilterTag == "nationality"){
                        weeks2.push(`${detail.year}_${detail.nationality}`);
                    } else if (selectedFilterTag == "area"){
                        weeks2.push(`${detail.year}_${detail.area}`);
                    }
                    else {
                        weeks2.push(`${detail.year}`);
                    }
                    
                }
                else if (selectedTime === "2") {
                    weeks2.push(`${detail.year}/${detail.month}`);
                }
                else {
                    weeks2.push(`${detail.year}/${detail.week}`);
                }
            }
            
            infectedCounts2.push(detail.infected);
            if (detail.isPrediction) {
                infectedBackgroundColors2.push('lightblue'); // Color for predicted infected 
                deathCounts2.push({ value: detail.death, isPrediction: true });
            } else {
                infectedBackgroundColors2.push('grey'); // Default color for actual infected data
                deathCounts2.push({ value: detail.death, isPrediction: false });
            }
        });

        const name_th = group.epidem_report_name_th == undefined ? group.epidem_group_name_th : group.epidem_report_name_th;
        const gid = group.epidem_report_id == undefined ? group.epidem_group_id : group.epidem_report_id;
        groupNameMap2[gid] = name_th;
        groupIds2.push(gid);

        // Add spacing for visual separation between groups
        weeks2.push('');
        infectedCounts2.push(0);
        infectedBackgroundColors2.push('rgba(0,0,0,0)'); // Transparent for spacing
    });

    console.log("dc", deathCounts);

    const data = {
        labels: weeks,
        datasets: [
            {
                label: 'Infected',
                data: infectedCounts,
                backgroundColor: infectedBackgroundColors,
                borderWidth: 1,
                type: 'bar',
                categoryPercentage: 0.8,
                barPercentage: 0.6,
                yAxisID: 'y-axis-1',
                order: 1,
            },
            {
                label: 'Deaths',
                data: deathCounts.map(d => d.value),
                type: 'line',
                segment: {
                    borderColor: ctx => {
                        // Get the indices for the start and end points of the current segment
                        let startIndex = ctx.p0DataIndex;
                        let endIndex = ctx.p1DataIndex;
                
                        // Safely get the isPrediction value for start and end points
                        let isStartPrediction = deathCounts[startIndex]?.isPrediction;
                        let isEndPrediction = deathCounts[endIndex]?.isPrediction;
                
                        // Determine the color of the segment
                        if (isStartPrediction === true && isEndPrediction === true) {
                            // Both points are predictions
                            return 'darkred';
                        } else if (isStartPrediction === false && isEndPrediction === false) {
                            // Neither point is a prediction
                            return 'lightblue';
                        } else {
                            // One point is a prediction and the other is not
                            // Here, you can decide how you want to color these "transition" segments
                            // For example, 'mixedColor', 'darkred', or 'lightblue'
                            return 'mixedColor'; 
                        }
                    },
                },
                
                
                borderWidth: 2,
                fill: false,
                yAxisID: 'y-axis-2',
                categoryPercentage: 0.8,
                barPercentage: 0.6,
                order: 2,
            }
        ]
    };

    console.log(deathCounts2);

    const data2 = {
        labels: weeks2,
        datasets: [
            {
                label: 'Infected',
                data: infectedCounts2,
                backgroundColor: infectedBackgroundColors,
                borderWidth: 1,
                type: 'bar',
                categoryPercentage: 0.8,
                barPercentage: 0.6,
                yAxisID: 'y-axis-1',
                order: 1,
            },
            {
                label: 'Deaths',
                data: deathCounts2.map(d => d.value),
                type: 'line',
                segment: {
                    borderColor: ctx => {
                        // Get the indices for the start and end points of the current segment
                        let startIndex = ctx.p0DataIndex;
                        let endIndex = ctx.p1DataIndex;
                
                        // Safely get the isPrediction value for start and end points
                        let isStartPrediction = deathCounts2[startIndex]?.isPrediction;
                        let isEndPrediction = deathCounts2[endIndex]?.isPrediction;
                
                        // Determine the color of the segment
                        if (isStartPrediction === true && isEndPrediction === true) {
                            // Both points are predictions
                            return 'darkred';
                        } else if (isStartPrediction === false && isEndPrediction === false) {
                            // Neither point is a prediction
                            return 'lightblue';
                        } else {
                            // One point is a prediction and the other is not
                            // Here, you can decide how you want to color these "transition" segments
                            // For example, 'mixedColor', 'darkred', or 'lightblue'
                            return 'mixedColor'; 
                        }
                    },
                },
                               
                borderWidth: 2,
                fill: false,
                yAxisID: 'y-axis-2',
                categoryPercentage: 0.8,
                barPercentage: 0.6,
                order: 2,
            }
        ]
    };

    const separationPlugin = {
        id: 'separationPlugin',
        afterDraw: (chart, args, options) => {
          const ctx = chart.ctx;
          // Set font and alignment
          ctx.font = "bold 14px Arial";
          ctx.textAlign = "end";
          ctx.textBaseline = "bottom";
      
          const weeks = chart.options.weeks;
          const groupIds = chart.options.groupIds.slice(); // create a copy so that we don't modify the original array
      
          // Render group IDs and vertical separation lines
          chart.getDatasetMeta(0).data.forEach((bar, index) => {
            if (weeks[index] === '') {
                const position = bar.getCenterPoint();
                const secondLastBar = chart.getDatasetMeta(0).data[index];
                const xPos = secondLastBar._model.x;
                // ctx.fillText(`Group: ${groupIds.shift()}`, xPos, 60);
                // ctx.fillText(epidemGroupNameMap[`${groupIds.shift()}`], xPos, 60);
                
                ctx.save();
                ctx.translate(xPos, 40);
                ctx.rotate(-Math.PI / 2);
                ctx.fillStyle = 'darkgrey'; 
                ctx.fillText(groupNameMap[`${groupIds.shift()}`], 0, 0);
                ctx.restore();
                
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(position.x, chart.chartArea.top);
                ctx.lineTo(position.x, chart.chartArea.bottom);
                ctx.strokeStyle = "#000";
                ctx.lineWidth = 1;
                ctx.stroke();
                ctx.restore();

            }
          });
        }
      };

    const options2 = {
        legend: {
            display: false // Disable default legend
        },
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    beginAtZero: true,
                },
                {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-2',
                    grid: {
                        drawOnChartArea: false, // only want the grid lines for one axis to show up
                    },
                }
            ],
            x: {
                stacked: true
            }
        },
        weeks: weeks2,      
        groupIds: groupIds2
    };

    const options = {
        legend: {
            display: false // Disable default legend
        },
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    beginAtZero: true,
                },
                {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-2',
                    grid: {
                        drawOnChartArea: false, // only want the grid lines for one axis to show up
                    },
                }
            ],
            x: {
                stacked: true
            }
        },
        weeks: weeks,      
        groupIds: groupIds
    };    

    return (
        <div style={{ width: '100%', overflowX: 'auto', maxHeight: '1500px' }}>
            {getOutputType(selectedOutputType) === "indiv" && selectedFilterTag === "all" && (
                <EpidemicReportTableMD5 data={jsonData.data} selectedDisplay={selectedDisplay}/>
            )}
            {getOutputType(selectedOutputType) === "indiv" && selectedFilterTag === "gender" && (
                <EpidemicReportTableMD5Gender data={jsonData.data} selectedDisplay={selectedDisplay}/>
            )}
            {getOutputType(selectedOutputType) === "indiv" && ['age', 'nationality', 'area'].includes(selectedFilterTag) && (
                <EpidemicReportTableMD5Other data={jsonData.data} selectedDisplay={selectedDisplay}/>
            )}
            {getOutputType(selectedOutputType) === "group" && selectedFilterTag === "all" && (
                <EpidemicReportTableMD5Group data={jsonData.data} getGroupDetail={getGroupDetail} selectedDisplay={selectedDisplay}/>
            )}
            {/* {getOutputType(selectedOutputType) === "group" && selectedFilterTag === "gender" && (
                <EpidemicReportTableMD5GroupGender data={jsonData.data} getGroupDetail={getGroupDetail} selectedDisplay={selectedDisplay}/>
            )} */}
            {getOutputType(selectedOutputType) === "group" && ['gender', 'age', 'nationality', 'area'].includes(selectedFilterTag) && (
                <EpidemicReportTableMD5GroupDynamic data={jsonData.data} getGroupDetail={getGroupDetail} groupField={selectedFilterTag === 'age' ? 'age_range' : selectedFilterTag} selectedDisplay={selectedDisplay}/>
            )}


            <div style={{ minWidth: '1000px' }}>
                <div id="chart-legend" dangerouslySetInnerHTML={{ __html: customLegend }}></div>
                { getOutputType(selectedOutputType) === "indiv" && <Bar data={data2} options={options2} key={JSON.stringify(data2)}  />}
                { getOutputType(selectedOutputType) === "group" && <Bar data={data} options={options} plugins={[separationPlugin]} key={JSON.stringify(data)}  />}
            </div>
        </div>
    );
}

export default CombinedEpidemicChart;
