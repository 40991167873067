import React ,{ useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import EpidemicReportTableMD5 from './component/EpidemicReportTableMD5Compare'
import EpidemicReportTableMD5Group from './component/EpidemicReportTableMD5GroupCompare'

function getRandomNumber() {
    const min = 5;
    const max = 15;
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const epidemGroupNameMap = {
    1: "โรคติดต่ออันตราย",
    2: "โรคอุบัติใหม่",
    3: "โรคติดต่อระบบทางเดินหายใจ",
    4: "โรคติดเชื้อระบบประสาทส่วนกลาง",
    5: "โรคติดเชื้อที่นำโดยแมลง",
    6: "โรคติดเชื้อที่ป้องกันได้ด้วยวัคซีน",
    7: "โรคติดเชื้อจากการสัมผัส",
    8: "โรคติดเชื้อระบบทางเดินอาหารและน้ำ",
    9: "โรคติดต่อระหว่างสัตว์และคน",
    10: "โรคติดต่อทางเพศสัมพันธ์",
    11: "โรคอื่นๆ"
};

function CompareChart({
    selectedYearValue, 
    selectedWeekValue,
    selectedMonthValue, 
    selectedEndYearValue, 
    selectedEndWeekValue,
    selectedEndMonthValue,
    selectedTime,
    selectedOutputType,
    epidemSelected,
    searchTrigger,
    selectedDisplay
}) {
    const [jsonData, setJsonData] = useState(null);

    const getTimeFrame = (time) => {
        if (time === "1") return "year";
        if (time === "2") return "month";
        if (time === "3") return "week";
        return "week";  // default value
    }    

    const getOutputType = (type) => {
        if (type === "1") return "indiv";
        return "group";
    }

    const getGroupDetail = async (groupId) => {
        const requestBody = {
            "timeFrame": getTimeFrame(selectedTime),    // year, month, week
            "startYear": parseInt(selectedYearValue),
            "startWeek": parseInt(selectedWeekValue),
            "startMonth": parseInt(selectedMonthValue),
            "endYear": parseInt(selectedEndYearValue),
            "endWeek": parseInt(selectedEndWeekValue),
            "endMonth": parseInt(selectedEndMonthValue),
            "epidemSelected": epidemSelected,    
            "outputType": "indiv",   // indiv or group
            "filterEpidemByGroupId": groupId
        };
    
        try {
            const response = await axios.post('https://dev.epidemic.jumpai.tech/api/compare-epidemic', requestBody);
            return {
                data: response.data
            };
        } catch (error) {
            console.error('Error fetching group details:', error);
            return {
                data: `Details for group ${groupId}`
            };
        }
    };

    useEffect(() => {
        setJsonData(null);

        const requestBody = {
            "timeFrame": getTimeFrame(selectedTime),    // year, month, week
            "startYear": parseInt(selectedYearValue),
            "startWeek": parseInt(selectedWeekValue),
            "startMonth": parseInt(selectedMonthValue),
            "endYear": parseInt(selectedEndYearValue),
            "endWeek": parseInt(selectedEndWeekValue),
            "endMonth": parseInt(selectedEndMonthValue),
            "epidemSelected": epidemSelected,    
            "outputType": getOutputType(selectedOutputType)   // indiv or group
        };

        // Fetch data from the API when the component mounts
        axios.post('https://dev.epidemic.jumpai.tech/api/compare-epidemic', requestBody)
        .then(response => {
            setJsonData(response.data);
        })
        .catch(error => {
            console.error("Error fetching data:", error);
        });
    }, [searchTrigger]);


    if (!jsonData || !jsonData.data || !Array.isArray(jsonData.data)) {
        return null; // or return a loading spinner, or some placeholder
    }

    let infectedDataArray = {};

    const processData = (apiData) => {
        if (!apiData || !apiData.data) return { labels: [], datasets: [] };
    
        const labels = Array.from(new Set(apiData.data.flatMap(d => d.details.map(detail => detail.year))));
        // labels.sort();
    
        const categories = ['infected', 'death', 'unknown'];
        const colorMapping = {
            'infected': '#FF5733',
            'death': '#FFC300',
            'unknown': '#DAF7A6'
        };
    
        const datasets = [];
    
        apiData.data.forEach(disease => {
            const diseaseDatasets = categories.map(category => {
                return {
                    label: `${disease.epidem_report_name_th == undefined ? disease.epidem_group_name_th : disease.epidem_report_name_th} - ${category}`, 
                    data: labels.map(year => {
                        const detailForYear = disease.details.find(detail => detail.year === year);
                        return detailForYear ? detailForYear[category] : 0;
                    }),
                    backgroundColor: colorMapping[category],
                    stack: disease.epidem_report_name_th == undefined ? disease.epidem_group_name_th : disease.epidem_report_name_th // This ensures each disease's data stacks together for each year.
                };
            });
            datasets.push(...diseaseDatasets);
        });
    
        return { labels, datasets };
    };    
    
   
    const dataLabelPlugin = {
        // afterDatasetsDraw: (chart) => {
        //     const ctx = chart.ctx;
        //     ctx.save();
    
        //     // Define line properties
        //     ctx.strokeStyle = 'darkred';
        //     ctx.lineWidth = 1.5;
    
        //     const diseasePoints = {}; // To store the X, Y points for each disease and year
    
        //     // First, find the X, Y points for each disease and year
        //     chart.data.datasets.forEach((dataset, datasetIndex) => {
        //         const meta = chart.getDatasetMeta(datasetIndex);
        //         const diseaseName = dataset.label.split(' - ')[0];
    
        //         meta.data.forEach((bar, barIndex) => {
        //             if (!diseasePoints[diseaseName]) {
        //                 diseasePoints[diseaseName] = [];
        //             }
        //             const topY = Math.min(bar._model.base, bar._model.y); // Take the minimum Y value (since Y is inverted)
        //             const x = bar._model.x;
        //             diseasePoints[diseaseName].push({x, y: topY});
        //         });
        //     });
    
        //     // Now, draw lines using the X, Y points
        //     Object.keys(diseasePoints).forEach(diseaseName => {
        //         ctx.beginPath();
        //         diseasePoints[diseaseName].sort((a, b) => a.x - b.x).forEach((point, index) => { // Sort by X position to ensure correct order
        //             if (index === 0) {
        //                 ctx.moveTo(point.x, point.y);
        //             } else {
        //                 ctx.lineTo(point.x, point.y);
        //             }
        //         });
        //         ctx.stroke();
        //     });
    
        //     ctx.restore();
        // }
    };
      
      
    const data = processData(jsonData);

    const options = {
        tooltips: {
            enabled: true,
            mode: 'point',
            callbacks: {
                label: function(tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    let value = tooltipItem.yLabel;
                    let customInfo = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / data.datasets[Math.floor(tooltipItem.datasetIndex - (tooltipItem.datasetIndex % 3))].data[tooltipItem.index];

                    console.log(tooltipItem);
                    console.log(data);
    
                    return `${label}: ${value} | คิดเป็น: ${customInfo * 100} % ของจำนวนผู้ป่วย`;
                }
            }
        },
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            labels: {
                filter: function(item, chart) {
                    // Check if this category has been added to the legend already
                    if (!chart.legendAlreadyAdded) {
                        chart.legendAlreadyAdded = {};
                    }
                    const category = item.text.split(' - ')[1]; // Extract category from the label
                    if (chart.legendAlreadyAdded[category]) {
                        return false;
                    }
                    chart.legendAlreadyAdded[category] = true;
                    return true;
                }
            }
        },
        plugins: [dataLabelPlugin]
    };
    
    // In your component render method:

    return (
        <div style={{ width: '100%', overflowX: 'auto', maxHeight: '1500px' }}>
            {getOutputType(selectedOutputType) === "indiv" && (
                <EpidemicReportTableMD5 data={jsonData.data} selectedDisplay={selectedDisplay} />
            )}
            {getOutputType(selectedOutputType) === "group" && (
                <EpidemicReportTableMD5Group data={jsonData.data} getGroupDetail={getGroupDetail} selectedDisplay={selectedDisplay} />
            )}
            <div style={{ minWidth: '1000px', minHeight: '400px' }}>
                <Bar data={data} options={options} plugins={[dataLabelPlugin]} />
            </div>
        </div>
    );
        

}

export default CompareChart;
