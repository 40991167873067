import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pie, Bar } from 'react-chartjs-2';
import TableSpecificDecease from './component/TableSpecificDecease'

function SpecificDiseasePieChart({
    selectedYearValue, 
    selectedWeekValue,
    selectedMonthValue, 
    selectedEndYearValue, 
    selectedEndWeekValue,
    selectedEndMonthValue,
    selectedTime,
    selectedOutputType,
    epidemSelected,
    selectedFilterTag,
    searchTrigger,
    selectedDisplay
}) {
    const [jsonData, setJsonData] = useState(null);

    const getTimeFrame = (time) => {
        if (time === "1") return "year";
        if (time === "2") return "month";
        if (time === "3") return "week";
        return "week";
    }

    const getOutputType = (type) => {
        if (type === "1") return "indiv";
        return "group";
    }

    useEffect(() => {
        setJsonData(null);
        const requestBody = {
            "timeFrame": getTimeFrame(selectedTime),
            "startYear": parseInt(selectedYearValue),
            "startWeek": parseInt(selectedWeekValue),
            "startMonth": parseInt(selectedMonthValue),
            "endYear": parseInt(selectedEndYearValue),
            "endWeek": parseInt(selectedEndWeekValue),
            "endMonth": parseInt(selectedEndMonthValue),
            "epidemSelected": epidemSelected,
            "filterTag": selectedFilterTag
        };

        axios.post('https://dev.epidemic.jumpai.tech/api/focus-epidemic', requestBody)
        .then(response => {
            setJsonData(response.data);
        })
        .catch(error => {
            console.error("Error fetching data:", error);
        });
    }, [searchTrigger]);

    if (!jsonData || !jsonData.data || !Array.isArray(jsonData.data)) {
        return null; // or return a loading spinner, or some placeholder
    }

    const processDataForPieChart = (data) => {
        let sums = {
            infected: {},
            death: {},
            treated: {},
            unknown: {}
        };
    
        data.details.forEach(detail => {
            const keys = {
                'gender': detail.gender,
                'age': detail.age_range,
                'nationality': detail.nationality,
                'area': detail.area
            };
            const genderMap = { '1': 'ชาย', '2': 'หญิง' };
            
            const key = selectedFilterTag === "gender" ? genderMap[keys[selectedFilterTag]] : keys[selectedFilterTag];
    
            if (!sums.infected[key]) {
                sums.infected[key] = 0;
                sums.death[key] = 0;
                sums.treated[key] = 0;
                sums.unknown[key] = 0;
            }
            
            sums.infected[key] += detail.infected;
            sums.death[key] += detail.death;
            sums.treated[key] += detail.treated;
            sums.unknown[key] += detail.unknown;
        });
        
        return sums;
    };

    const processDataForBarChart = (data) => {
        let barData = {
            labels: [],
            datasets: []
        };
    
        const categories = ['infected', 'death', 'treated', 'unknown'];
        const groupingKeys = ['gender', 'age_range', 'nationality', 'area'];
        const genderMap = { '1': 'ชาย', '2': 'หญิง' };
    
        // Initialize labels for all years
        const allYears = [...new Set(data.details.map(detail => detail.year))];
        barData.labels = [...allYears];
    
        data.details.forEach(detail => {
            const year = detail.year;
    
            groupingKeys.forEach(groupKey => {
                if (detail[groupKey] !== undefined) {
                    const groupValue = groupKey === 'gender' ? genderMap[detail[groupKey].toString()] : detail[groupKey].toString();
    
                    categories.forEach((category, index) => {
                        let datasetKey = `${groupValue}-${category}`;
                        let dataset = barData.datasets.find(d => d.label === datasetKey);
    
                        if (!dataset) {
                            dataset = {
                                label: datasetKey,
                                backgroundColor: `rgba(${index * 60}, ${100}, ${150 + index * 40}, 0.7)`,
                                stack: groupValue,
                                data: new Array(allYears.length).fill(0)
                            };
                            barData.datasets.push(dataset);
                        }
    
                        let labelIndex = barData.labels.indexOf(year);
                        dataset.data[labelIndex] += detail[category] || 0;
                    });
                }
            });
        });
    
        return barData;
    };
    

    let sums, barData;
    if (jsonData && jsonData.data) {
        sums = processDataForPieChart(jsonData.data[0]);
        barData = processDataForBarChart(jsonData.data[0]);
    }

    const pieChartStyle = {
        width: '400px',
        height: '230px',
        margin: '20px',
        textAlign: 'center' 
    };

    const colorMap = {};

    const getColorForKey = (key) => {
        if (!colorMap[key]) {
            // Generate a random color
            const hue = Math.floor(Math.random() * 360);
            const saturation = Math.floor(Math.random() * 100);
            const lightness = 50; // Fixed lightness for consistency
            colorMap[key] = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        }
        return colorMap[key];
    };

    const renderPieChart = (category) => {
        let labels = Object.keys(sums[category]);
        let values = labels.map(label => sums[category][label]);
    
        // Use the getColorForKey function to assign colors
        let colors = labels.map(label => getColorForKey(label));
    
        const dataForPieChart = {
            labels: labels,
            datasets: [{
                data: values,
                backgroundColor: colors,
            }]
        };
    
        return (
            <div style={pieChartStyle}>
                <h3>{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
                <Pie 
                    data={dataForPieChart} 
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                                display: false
                            }
                    }}
                />
            </div>
        );
    };
    
    

    const barChartStyle = {
        width: '100%',
        minHeight: '400px',
        margin: '20px',
        textAlign: 'center'
    };

    const renderBarChart = (barData) => {
        return (
            <div style={barChartStyle}>
                <Bar 
                    data={barData} 
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: true
                        },
                        scales: {
                            yAxes: [{
                                stacked: true,
                                ticks: {
                                    beginAtZero: true
                                }
                            }],
                            xAxes: [{
                                stacked: true
                            }]
                        }
                    }}
                />
            </div>
        );
    };

    function capitalize(str) {
        if (!str) return str; // return original string if it's empty or not a string
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div>
            <TableSpecificDecease data={jsonData.data} tag={capitalize(selectedFilterTag)} selectedDisplay={selectedDisplay} />
       
            <div style={{ display: 'flex', flexDirection: 'row', minHeight: '400px', width: '100%', overflowX: 'auto' }}>
                <div style={{ width: '100%', padding: '10px' }}>
                    {barData && renderBarChart(barData)}
                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                        {sums && Object.keys(sums).slice(0, 2).map((category) => renderPieChart(category))}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        {sums && Object.keys(sums).slice(2).map((category) => renderPieChart(category))}
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default SpecificDiseasePieChart;
