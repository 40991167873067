import React, { useState } from 'react';

const EpidemicReportTableMD5GroupDynamic = ({ data, getGroupDetail, groupField, selectedDisplay }) => {
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [groupDetails, setGroupDetails] = useState({});

    const toggleRowExpansion = async (id) => {
        if (expandedRows.has(id)) {
            setExpandedRows(prev => new Set([...prev].filter(eid => eid !== id)));
        } else {
            setExpandedRows(prev => new Set(prev.add(id)));
            if (!groupDetails[id]) {
                const details = await getGroupDetail(id);
                setGroupDetails(prev => ({ ...prev, [id]: details }));
            }
        }
    };

    const isRowExpanded = (id) => expandedRows.has(id);

    // Function to create a unique identifier for each time period
    const getTimePeriod = (detail) => {
        if ('week' in detail) {
            const paddedWeek = String(detail.week).padStart(2, '0');
        return `${detail.year}_${paddedWeek}`;
        } else if ('month' in detail) {
            return `${detail.year}_${detail.month}`;
        } else {
            return detail.year.toString();
        }
    };

    // Parse the data to get unique time periods
    const uniqueTimePeriods = Array.from(
        new Set(data.flatMap(report => report.details.map(getTimePeriod)))
    );

    const groupValues = Array.from(
        new Set(data.flatMap(report => report.details.map(detail => groupField === 'gender' ? (detail.gender === 1 ? 'Male' : 'Female') : detail[groupField] )))
    );

    // Create headers based on both unique time periods and group values
    const headers = uniqueTimePeriods.flatMap(timePeriod => 
        groupValues.map(groupValue => ({
            timePeriod,
            groupValue,
            subHeaders: [
                'สถานะการแจ้งเตือนโรคระบาด', 
                'จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี', 
                'ยืนยันผู้ป่วย',
                'เสียชีวิต'
            ]
        }))
    );


    const setColorClass = (colormd5) => {
        switch(colormd5) {
            case 1: return 'lightgrey';
            case 2: return 'green';
            case 3: return 'yellow';
            case 4: return 'orange';
            case 5: return 'red';
            default: return '';
        }
    };

    function setColorClassInfected(color20, color100, selectedDisplay) {
        if(selectedDisplay == "1") {
            switch(color20) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }

        if(selectedDisplay == "2") {
            switch(color100) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }
        
        return '';
    }

    const renderSubHeaders = (subHeader, timePeriod, groupValue) => {
        if (selectedDisplay === "3" || subHeader !== 'จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี') {
            return <th data-column={subHeader} key={`${timePeriod}-${subHeader}-${groupValue}`}>{subHeader}</th>;
        }
        return null;
    };

    const renderDataCells = (detail, key) => {
        if (key === 'infected' && detail) {
            return <td className={setColorClassInfected(detail.color20, detail.color100, selectedDisplay)}>{detail[key] || 0}</td>;
        }
        else if (selectedDisplay === "3" && detail && key === 'median') {
            return <td className={setColorClass(detail.colormd5)}>{detail[key] || 0}</td>;
        }
        else if ((key === 'death' || key === 'unknown') && detail) {
            return <td>{detail[key] || 0}</td>;
        }
        else if (selectedDisplay !== "3" && key === 'median') {
            return null;
        }
        return <td>0</td>;
    };

    const renderTotalMedianCell = (report) => {
        if (selectedDisplay === "3") {
            return <td className={setColorClass(Math.max(...report.details.map(d => d.colormd5 || 0)))}>
                       {report.totalMedian || 0}
                   </td>;
        }
        return null;
    };

    return (
        <table id="epidemicReportTable">
            <thead>
                <tr>
                    <th>โรค</th>
                    {headers.map(header => (
                        <th key={`${header.timePeriod}-${header.groupValue}`} colSpan={selectedDisplay === "3" ? 
                            header.subHeaders.length : 
                            header.subHeaders.length - 1}>
                            {`${header.timePeriod} - ${header.groupValue}`}
                        </th>
                    ))}
                    <th colSpan={selectedDisplay === "3" ? "4" : "2"}>รวม</th>
                </tr>
                <tr>
                    <th></th>
                    {headers.flatMap(header =>
                        header.subHeaders.map(subHeader => (
                            typeof subHeader === 'object'
                                ? subHeader.subHeaders.map(sh => <th key={`${header.timePeriod}-${header.groupValue}-${subHeader.header}`}>{sh}</th>)
                                : renderSubHeaders(subHeader, header.timePeriod, header.groupValue)
                        ))
                    )}
                    {selectedDisplay === "3" && <th>จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี</th>}
                    <th id='infected_header'>ยืนยันผู้ป่วย</th>
                    <th id='deaths_header'>เสียชีวิต</th>
                </tr>
            </thead>
            <tbody>
                {data.map(report => (
                    <React.Fragment key={report.epidem_group_id}>
                        <tr>
                            <td>
                                <button onClick={() => toggleRowExpansion(report.epidem_group_id)}>
                                    {isRowExpanded(report.epidem_group_id) ? '-' : '+'}
                                </button>
                                {report.epidem_group_name_th}
                            </td>
                            {headers.map(header => {
                                // Find detail based on timePeriod and groupField
                                const detail = report.details.find(d => getTimePeriod(d) === header.timePeriod && (d[groupField] === 1 ? 'Male' : 'Female') === header.groupValue) || {};
                                return (
                                    <React.Fragment key={`${report.epidem_group_id}-${header.timePeriod}-${header.groupValue}`}>
                                        <td>{detail.flag > 0 ? '!'.repeat(detail.flag) : '-'}</td>
                                        {selectedDisplay === "3" && renderDataCells(detail, 'median')}
                                        {renderDataCells(detail, 'infected')}
                                        {renderDataCells(detail, 'death')}
                                    </React.Fragment>
                                );
                            })}
                            {renderTotalMedianCell(report)}
                            <td className={setColorClassInfected(Math.max(...report.details.map(d => d.color20 || 0)), Math.max(...report.details.map(d => d.color100 || 0)), selectedDisplay)}>
                                {report.totalInfected}
                            </td>
                            <td>{report.totalDeath}</td>
                        </tr>

                        {isRowExpanded(report.epidem_group_id) && groupDetails[report.epidem_group_id] && 
                            groupDetails[report.epidem_group_id].data.data.map(detail => (
                                <tr key={detail.epidem_report_id}>
                                    <td>{detail.epidem_report_name_th}</td>
                                    {headers.map(header => {
                                        const detailx = detail.details.find(d => getTimePeriod(d) === header.timePeriod && d[groupField] === header.groupValue) || {};
                                        return (
                                            <React.Fragment key={`${detail.epidem_report_id}-${header.timePeriod}-${header.groupValue}`}>
                                                <td>{detailx.flag > 0 ? '!'.repeat(detailx.flag) : '-'}</td>
                                                {selectedDisplay === "3" && renderDataCells(detailx, 'median')}
                                                {renderDataCells(detailx, 'infected')}
                                                {renderDataCells(detailx, 'death')}
                                            </React.Fragment>
                                        );
                                    })}
                                    {renderTotalMedianCell(detail)}
                                    <td>{detail.totalInfected}</td>
                                    <td>{detail.totalDeath}</td>
                                </tr>
                            ))
                        }
                    </React.Fragment>
                ))}
            </tbody>

        </table>
    );
};

export default EpidemicReportTableMD5GroupDynamic;
