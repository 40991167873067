import React from 'react';

const EpidemicReportTableMD5Other = ({ data, selectedDisplay }) => {
    // Process and group data by year and dynamic fields
    const getTimePeriod = (detail) => {
        if ('week' in detail) {
            return `${detail.year}_week_${detail.week}`;
        } else if ('month' in detail) {
            return `${detail.year}_${detail.month}`;
        } else {
            return detail.year.toString();
        }
    };

    // Process and group data by dynamic time periods and fields
    const processAndGroupData = (data) => {
        const timePeriods = new Set();
        const dynamicFields = { gender: new Set(), age_range: new Set(), nationality: new Set(), area: new Set() };

        data.forEach(report => {
            report.details.forEach(detail => {
                timePeriods.add(getTimePeriod(detail));
                Object.keys(dynamicFields).forEach(field => {
                    if (detail[field] !== undefined) {
                        dynamicFields[field].add(detail[field]);
                    }
                });
            });
        });

        return { timePeriods: Array.from(timePeriods), dynamicFields };
    };

    const { timePeriods, dynamicFields } = processAndGroupData(data);

    function setColorClass(colormd5) {
        switch(colormd5) {
            case 1: return 'lightgrey';
            case 2: return 'green';
            case 3: return 'yellow';
            case 4: return 'orange';
            case 5: return 'red';
            default: return '';
        }
    }

    function setColorClassInfected(color20, color100, selectedDisplay) {
        if(selectedDisplay == "1") {
            switch(color20) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }

        if(selectedDisplay == "2") {
            switch(color100) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }
        
        return '';
    }

    return (
        <table id="epidemicReportTable">
            <thead>
                <tr>
                    <th>โรค</th>
                    {timePeriods.flatMap(timePeriod => 
                        Object.entries(dynamicFields).flatMap(([field, values]) => 
                            Array.from(values).map(value => (
                                <th key={`${timePeriod}-${field}-${value}`} colSpan={4}>
                                    {timePeriod} - {value}
                                </th>
                            ))
                        )
                    )}
                    <th colSpan="3">รวม</th>
                </tr>
                <tr>
                    <th></th>
                    {timePeriods.flatMap(timePeriod => (
                        Object.entries(dynamicFields).flatMap(([field, values]) => 
                            Array.from(values).flatMap(value => (
                                <>
                                    <th key={`${timePeriod}-${field}-${value}-flag`}>สถานะการแจ้งเตือนโรคระบาด</th>
                                    <th key={`${timePeriod}-${field}-${value}-median`}>จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี</th>
                                    <th key={`${timePeriod}-${field}-${value}-infected`}>ยืนยันผู้ป่วย</th>
                                    <th key={`${timePeriod}-${field}-${value}-death`}>เสียชีวิต</th>
                                </>
                            ))
                        )
                    ))}
                    <th>จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี</th>
                    <th id='infected_header'>ยืนยันผู้ป่วย</th>
                    <th id='deaths_header'>เสียชีวิต</th>
                </tr>
            </thead>
            <tbody>
                {data.map(report => (
                    <tr key={report.epidem_report_id}>
                        <td>  - {report.epidem_report_name_th}</td>
                        {timePeriods.flatMap(timePeriod => (
                            Object.entries(dynamicFields).flatMap(([field, values]) => 
                                Array.from(values).map(value => {
                                    const detail = report.details.find(d => getTimePeriod(d) === timePeriod && d[field] === value);
                                    return (
                                        <React.Fragment key={`${timePeriod}-${field}-${value}`}>
                                            <td>{detail ? (detail.flag > 0 ? '!'.repeat(detail.flag) : '-') : '-'}</td>
                                            <td className={setColorClass(detail ? detail.colormd5 : 0)}>
                                                {detail ? detail.median || 0 : 0}
                                            </td>
                                            <td>{detail ? detail.infected || 0 : 0}</td>
                                            <td>{detail ? detail.death || 0 : 0}</td>
                                        </React.Fragment>
                                    );
                                })
                            )
                        ))}
                        <td className={setColorClass(Math.max(...report.details.map(d => d.colormd5 || 0)))}>
                            {report.totalMedian || 0}
                        </td>
                        <td>{report.totalInfected}</td>
                        <td>{report.totalDeath}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default EpidemicReportTableMD5Other;
